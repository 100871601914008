import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FosterTable } from "./FosterTable/FosterTable";
import { fosterList } from "../../../api/FosterSubmit";
import { useSelector } from "react-redux";
import {
  IsWithinLastMonth,
  isWithinLastWeek,
} from "../../shared/Utils/DateFilters";
import Select from "@mui/material/Select";
import { MenuItem } from "@mui/material";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CustomArrowIcon = () => <FontAwesomeIcon icon={faChevronDown} />;

export default function FosterList() {
  const [localUserData, setLocalUserData] = useState(null);
  const [fosterData, setFosterData] = useState(null);
  const [allFosters, setAllFosters] = useState(null);

  const userData = useSelector((state) => state.userData.userData);
  const [filter, setFilter] = useState("Filter by");

  const getFosterList = async () => {
    try {
      const response = await fosterList();

      if (response) {
        setFosterData(response);
        setAllFosters(response);
      }
    } catch (error) {
      console.error("Error getting Fosters: ", error);
    }
  };

  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      setLocalUserData(JSON.parse(userData));
    }
  }, [userData]);

  useEffect(() => {
    if (localUserData) {
      getFosterList();
    }
  }, [localUserData]);

  const handleFilterChange = (e) => {
    const filter = e.target.value;
    setFilter(filter);

    let filterData = [];
    if (filter === "This Week") {
      filterData = fosterData.filter(() => {
        return isWithinLastWeek(new Date(fosterData.created_at));
      });
      setFosterData(filterData);
    } else if (filter === "This Month") {
      filterData = fosterData.filter(() => {
        return IsWithinLastMonth(new Date(fosterData.created_at));
      });
      setFosterData(filterData);
    } else {
      setFosterData(allFosters);
    }
  };

  return (
    <>
      <div className="mx-auto sm:ml-14 pt-12">
        <div className={`flex justify-center flex-col items-center gap-2`}>
          <h2 className="text-3xl text-left font-bold my-2 text-center !text-orange">
            Fosters
          </h2>
        </div>
        <div>
          <Select
            variant="standard"
            className="!text-gray-600 w-36 my-8"
            value={filter}
            IconComponent={CustomArrowIcon}
            onChange={handleFilterChange}
            name="filter"
          >
            <MenuItem value="Filter by">Filter by</MenuItem>
            <MenuItem value="This Week">This Week</MenuItem>
            <MenuItem value="This Month">This Month</MenuItem>
          </Select>
        </div>
        {fosterData ? <FosterTable data={fosterData} /> : ""}
      </div>
    </>
  );
}
