import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import ArticleForm from "./ArticleForm";
import { useState } from "react";

const CreateArticle = ({ setCreateArticle }) => {
  const articleData = {
    category: "articles",
    image: "",
    title: sessionStorage.getItem("title") || "",
    content: sessionStorage.getItem("content") || "",
    success: "",
    error: "",
  };
  const [previewOpen, setPreviewOpen] = useState(false);

  return (
    <>
      {!previewOpen && (
        <div className="flex flex-row mb-4 w-full">
          <h1 className={`cursor-pointer`} onClick={setCreateArticle}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </h1>
          <h1 className="text-orange flex-grow">Create an Article</h1>
        </div>
      )}
      <div className="border px-5">
        <ArticleForm
          articleData={articleData}
          type={"create"}
          previewState={{ previewOpen, setPreviewOpen }}
        />
      </div>
    </>
  );
};

export default CreateArticle;
