import React, { useEffect, useState } from "react";
import { LinearProgress } from "@mui/material";
import { getPaginatedCampaigns } from "../../../api/Campaigns";

const ProgressBar = ({ npData, campaigns = null }) => {
  const [progress, setProgress] = useState(25);

  useEffect(() => {
    if (npData?.stripe_account) {
      setProgress(50);
      if (npData?.stripe_account.charges_enabled) {
        setProgress(75);
      }
    }
  }, [npData]);

  useEffect(() => {
    const fetchCampaignData = async () => {
      try {
        let fetchedCampaigns = null;
        if (campaigns === null) {
          const response = await getPaginatedCampaigns(
            1,
            "",
            "",
            "",
            npData.id,
            ""
          );
          fetchedCampaigns = response.results;
        } else {
          fetchedCampaigns = campaigns;
        }

        if (fetchedCampaigns && fetchedCampaigns.length > 0) {
          setProgress(100);
        }
      } catch (error) {
        console.error("Error fetching campaign data:", error);
      }
    };

    if (progress >= 75) {
      fetchCampaignData();
    }
  }, [progress]);

  return (
    <div>
      <span>{progress}%</span>
      <LinearProgress variant="determinate" value={progress} />
    </div>
  );
};

export default ProgressBar;
