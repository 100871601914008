import { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import {
  createUpdate,
  fetchUpdates,
  updateUpdate,
  deleteUpdate,
} from "../../../api/Updates";
import populateFormData from "../../shared/Utils/populateFormData";

const Updates = () => {
  const [updates, setUpdates] = useState([]);
  const [newUpdate, setNewUpdate] = useState();
  const [disabled, setDisabled] = useState(-1);
  const [confirmDelete, setConfirmDelete] = useState(-1);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    const getUpdates = async () => {
      const response = await fetchUpdates();
      if (response) {
        setUpdates(response);
      }
    };

    getUpdates();
  }, []);

  // Submit a new update
  const handleUpdateSubmit = async (e) => {
    e.preventDefault();

    try {
      let formData = new FormData();
      formData.append("description", newUpdate);
      const response = await createUpdate(formData);
      if (response) {
        setSuccessMsg("Success!");
        setUpdates([...updates, response]); // Add new update to state
        setNewUpdate(""); // Clear input field
      }
    } catch (error) {
      setErrorMsg("Can't create an update!");
      console.error("Can't create an update!", error);
    }
  };

  // Update an existing update
  const handleUpdateUpdate = async (id, description) => {
    try {
      const formData = new FormData();

      formData.append("description", description);

      const response = await updateUpdate(id, formData);
      if (response) {
        setSuccessMsg("Update saved successfully!");
        setUpdates(
          updates.map((update) =>
            update.id === id ? { ...update, description } : update
          )
        );
        setDisabled(-1); // Reset the disabled state to indicate editing is done
      }
    } catch (error) {
      setErrorMsg("Can't update the update!");
      console.error("Can't update the update!", error);
    }
  };

  // Handle input change for updating a specific update
  const handleUpdateChange = (e, id) => {
    const newDescription = e.target.value;

    setUpdates(
      updates.map((update) =>
        update.id === id ? { ...update, description: newDescription } : update
      )
    );
  };

  // Delete an update
  const handleDeleteTask = async (id) => {
    try {
      const response = await deleteUpdate(id);

      if (response) {
        setSuccessMsg("Update deleted successfully!");
        setUpdates(updates.filter((update) => update.id !== id));
        setConfirmDelete(-1);
      }
    } catch (error) {
      setErrorMsg("Can't delete the update!");
      console.error("Can't delete the update!", error);
    }
  };

  return (
    <div className="flex flex-col gap-8">
      <h1 className="text-orange">Updates for Nonprofits</h1>
      <section className="pr-12">
        <form
          onSubmit={handleUpdateSubmit}
          className="flex justify-center items-center gap-6 w-full"
        >
          <TextField
            name="update"
            value={newUpdate}
            onChange={(e) => setNewUpdate(e.target.value)}
            label="Enter update here"
            className="grow"
          />
          <button
            className="bg-orange p-4 rounded-xl text-white hover:bg-orange-dark1"
            type="submit"
          >
            SUBMIT
          </button>
        </form>
        {successMsg && <p className="text-green-500">{successMsg}</p>}
        {errorMsg && <p className="text-red-500">{errorMsg}</p>}
      </section>

      <section className="flex justify-center items-centers pr-12">
        <table className="grow table-fixed !border-separate">
          <thead>
            <tr>
              <td className="text-sm font-medium text-neutral-500">Updates</td>
              <td></td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {updates.map((update, id) => (
              <tr key={id} className="cursor-pointer">
                <td className="bg-sky-50 rounded-l-xl">
                  <input
                    type="text"
                    value={update.description}
                    disabled={disabled !== update.id}
                    className="text-sm text-neutral-600 enabled:!bg-white disabled:bg-sky-50 disabled:border-none text-center"
                    onChange={(e) => handleUpdateChange(e, update.id)}
                  />
                </td>
                <td className="bg-sky-50 mb-14">
                  {disabled === update.id ? (
                    <button
                      onClick={() =>
                        handleUpdateUpdate(update.id, update.description)
                      }
                      className="bg-blue p-2 px-4 rounded text-white hover:bg-blue-dark1"
                    >
                      SAVE
                    </button>
                  ) : (
                    <button
                      onClick={() => setDisabled(update.id)}
                      className="bg-blue p-2 rounded text-white hover:bg-blue-dark1"
                    >
                      UPDATE
                    </button>
                  )}
                </td>
                <td className="bg-sky-50 mb-14 rounded-r-xl">
                  {confirmDelete === update.id ? (
                    <button
                      onClick={() => handleDeleteTask(update.id)}
                      className="bg-red-500 p-2 rounded text-white hover:bg-red-800"
                    >
                      CONFIRM
                    </button>
                  ) : (
                    <button
                      onClick={() => setConfirmDelete(update.id)}
                      className="bg-red-500 p-2 px-4 rounded text-white hover:bg-red-800"
                    >
                      DELETE
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </div>
  );
};

export default Updates;
