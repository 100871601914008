import React, { useState, useEffect } from "react";
import classes from "./CreateBlog.module.css";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Editor from "../../Dashboard/CampaignModal/Editor/Editor";
import GeneralButton from "../../shared/Buttons/GeneralButton";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import { lineBreaks } from "../../shared/Utils/LineBreaks";
import Quill from "quill";
import { updateBlog } from "../../../api/UpdateBlog";
import { getNonprofits } from "../../../api/Nonprofits";
import DisplayBlogs from "./DisplayBlogs";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PreviewBlog from "./PreviewBlog";

const Delta = Quill.import("delta");

const maxSize = 3 * 1024 * 1024;

const EditBlogPage = ({ blogInfo, onClose }) => {
  const [blogData, setBlogData] = useState({
    ...blogInfo,
    error: "",
    success: "",
  });
  const [createError, setCreateError] = useState("");
  const [ifImageExists, setIfImageExists] = useState({
    image_1: false,
    image_2: false,
    image_3: false,
    image_4: false,
    image_5: false,
    image_6: false,
    image_7: false,
    image_8: false,
    image_9: false,
    image_10: false,
  });
  const [npDropdown, setNpDropdown] = useState(true);
  const [npInfo, setNpInfo] = useState([]);
  const [ifUpdateSuccess, setIfUpdateSuccess] = useState(false);
  const [redirectToDisplay, setRedirectToDisplay] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);

  useEffect(() => {
    const fetchNP = async () => {
      try {
        const response = await getNonprofits();
        setNpInfo(response);
        return response;
      } catch (e) {
        throw e;
      }
    };

    fetchNP();
  }, []);

  // enable npDropdrown if category is "success"
  useEffect(() => {
    if (blogData.category === "success") {
      setNpDropdown(false);
    } else {
      setNpDropdown(true);
    }
  }, [blogData.category]);

  // Setting state on if image exists
  useEffect(() => {
    const newIfImageExists = {};

    for (const key in blogData) {
      if (key.includes("image")) {
        // Check if the value is an empty string
        if (blogData[key] === "") {
          newIfImageExists[key] = false;
        } else if (!(blogData[key] instanceof File)) {
          newIfImageExists[key] = true;
        }
      }
    }

    setIfImageExists((prevState) => ({
      ...prevState,
      ...newIfImageExists,
    }));
  }, [blogData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBlogData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Checking if paragraph is ""
  const handleEditorChange = (content, delta, source, quill, name) => {
    const sanitizedContent =
      content === "<p><br></p>" || content === '<p>""</p>' ? "" : content;
    setBlogData((prevState) => ({
      ...prevState,
      [name]: sanitizedContent,
    }));
  };
  <Editor
    name={"paragraph_1"}
    onTextChange={handleEditorChange}
    defaultValue={blogData.paragraph_1}
  />;

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const { name, value } = e.target;

    // if file is different from current image, remove current image preview
    if (value !== blogData[name]) {
      setIfImageExists((prevState) => ({
        ...prevState,
        [name]: false,
      }));
    }

    // if file is large, handle error
    let error = "";
    let isTooLarge = false;

    if (files.length > 0) {
      // error handling if removed file
      if (files[0]["size"] > maxSize) {
        error = "File size is too large (Must be under 3 MB).";
        isTooLarge = true;
      } else {
        setCreateError("");
      }
    }

    if (isTooLarge) {
      // error handling if file is too large
      setBlogData((prevState) => ({ ...prevState, error: error }));
      setCreateError(error);
    } else {
      setBlogData((prevState) => ({
        ...prevState,
        [name]: files[0],
        error: "", // Clear any previous error
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let formData = new FormData();

      Object.keys(blogData).forEach((key) => {
        if (
          key !== "" &&
          key !== "success" &&
          key !== "nonprofit" &&
          key !== "error" &&
          !key.includes("image") &&
          key.includes("paragraph") === false &&
          key !== "created_at"
        ) {
          formData.append(key, blogData[key]);
        }

        if (key.includes("paragraph")) {
          const content = blogData[key];
          if (content !== "" && content !== "<p><br></p>") {
            formData.append(key, content);
          } else {
            formData.append(key, "");
          }
        } else if (key.includes("image")) {
          if (blogData[key] instanceof File) {
            formData.append(key, blogData[key]);
          }
        } else if (key === "category") {
          if (blogData[key] === "success") {
            formData.append("nonprofit", blogData["nonprofit"]);
          }
        }
      });

      const response = await updateBlog(Number(blogData.id), formData);
      setCreateError("");
      setBlogData((prevState) => ({
        ...prevState,
        success: "Blog updated successfully!",
        error: "",
      }));

      setIfUpdateSuccess(true);

      setTimeout(() => {
        setRedirectToDisplay(true);
      }, 2000);
    } catch (e) {
      const errorMessage = errorHandler(e);
      const errorMessageWithBreaks = lineBreaks(errorMessage);
      setCreateError(errorMessageWithBreaks);
    }
  };

  const handlePreviewClick = (event) => {
    event.stopPropagation();
    setPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setPreviewOpen(false);
  };

  if (previewOpen) {
    return <PreviewBlog blog={blogData} onClose={() => handleClosePreview()} />;
  }

  if (redirectToDisplay) {
    return <DisplayBlogs />;
  }

  return (
    <>
      <div className="flex flex-row mb-4">
        <h1 className={`${classes.back_button} w-1/5`} onClick={onClose}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </h1>
        <h1 className="text-orange">Edit a Blog</h1>
      </div>
      <div className={`${classes.blog_form} border p-12 m-2.5`}>
        <form
          className={`${classes.post_form} w-full`}
          onSubmit={(e) => handleSubmit(e)}
        >
          <section className={`${classes.top_section} px-5`}>
            <div className={`${classes.cover_image} my-2.5 px-2`}>
              <InputLabel
                className={`${classes.subtitles}`}
                id="demo-simple-select-label"
              >
                Cover Image
              </InputLabel>
              <input
                className={`border p-2 mb-3`}
                type="file"
                name="image_1"
                onChange={(e) => handleImageChange(e)}
              />
              {ifImageExists.image_1 && blogData.image_1 ? (
                <p className={`text-left`}>
                  Current image:
                  <img
                    className={` size-20`}
                    src={blogData.image_1}
                    alt={blogData.image_1}
                  />
                </p>
              ) : null}
            </div>
            <TextField
              className="px-2"
              id="title"
              label="Title"
              name="title"
              onChange={handleChange}
              value={blogData.title}
            />
            <TextField
              style={{ paddingLeft: "10px", paddingRight: "5px" }}
              id="video_urls"
              label="Video URLs (comma separated)"
              name="video_urls"
              onChange={handleChange}
              value={blogData.video_urls}
            />
            <FormControl
              sx={{ m: 1, width: 222.97 }}
              size="normal"
              className="px-0"
            >
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                required
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={blogData.category}
                label="category"
                onChange={handleChange}
                name="category"
              >
                <MenuItem value="blogs">Blog</MenuItem>
                <MenuItem value="success">Success Story</MenuItem>
                <MenuItem value="corporate">Corporate Partnership</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              sx={{ m: 1, width: 222.97 }}
              size="normal"
              className="px-0"
            >
              <InputLabel id="demo-simple-select-label">Nonprofit</InputLabel>
              <Select
                disabled={npDropdown}
                required
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={
                  blogData.category === "success" ? blogData.nonprofit : ""
                }
                label="nonprofit"
                onChange={handleChange}
                name="nonprofit"
              >
                {npInfo.length > 0 &&
                  npInfo.map((np) => (
                    <MenuItem key={np.id} value={np.id}>
                      {np.org_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </section>
          <section className={`${classes.middle_section} w-full px-5`}>
            <div className={`${classes.img_p_section} border p-2 my-2.5`}>
              <InputLabel
                className={`${classes.subtitles}`}
                id="demo-simple-select-label"
              >
                Paragraph 1
              </InputLabel>
              <div className={`${classes.custom_quill_wrapper}`}>
                <Editor
                  name={"paragraph_1"}
                  onTextChange={handleEditorChange}
                  defaultValue={blogData.paragraph_1}
                />
              </div>
            </div>
            <div className={`${classes.img_p_section} border p-2 my-2.5`}>
              <div className={`flex flex-row`}>
                <div className="flex flex-col">
                  <InputLabel
                    className={`${classes.subtitles}`}
                    id="demo-simple-select-label"
                  >
                    Paragraph 2
                  </InputLabel>
                  <input
                    className={`border p-2 rounded mb-2 flex justify-center`}
                    type="file"
                    name="image_2"
                    onChange={handleImageChange}
                  />
                </div>
                {ifImageExists.image_2 && blogData.image_2 ? (
                  <p className={`text-left pl-3 pb-3`}>
                    Current image:
                    <img
                      className={` size-20`}
                      src={blogData.image_2}
                      alt={blogData.image_2}
                    />
                  </p>
                ) : null}
              </div>
              <div className={`${classes.custom_quill_wrapper}`}>
                <Editor
                  name="paragraph_2"
                  onTextChange={handleEditorChange}
                  defaultValue={blogData.paragraph_2}
                />
              </div>
            </div>
            <div className={`${classes.img_p_section} border p-2 my-2.5`}>
              <div className={`flex flex-row`}>
                <div className="flex flex-col">
                  <InputLabel
                    className={`${classes.subtitles}`}
                    id="demo-simple-select-label"
                  >
                    Paragraph 3
                  </InputLabel>
                  <input
                    className={`border p-2 rounded mb-2 flex justify-center`}
                    type="file"
                    name="image_3"
                    onChange={handleImageChange}
                  />
                </div>
                {ifImageExists.image_3 && blogData.image_3 ? (
                  <p className={`text-left pl-3 pb-3`}>
                    Current image:
                    <img
                      className={` size-20`}
                      src={blogData.image_3}
                      alt={blogData.image_3}
                    />
                  </p>
                ) : null}
              </div>
              <div className={`${classes.custom_quill_wrapper}`}>
                <Editor
                  name="paragraph_3"
                  onTextChange={handleEditorChange}
                  defaultValue={blogData.paragraph_3}
                />
              </div>
            </div>
            <div className={`${classes.img_p_section} border p-2 my-2.5`}>
              <div className={`flex flex-row`}>
                <div className="flex flex-col">
                  <InputLabel
                    className={`${classes.subtitles}`}
                    id="demo-simple-select-label"
                  >
                    Paragraph 4
                  </InputLabel>
                  <input
                    className={`border p-2 rounded mb-2 flex justify-center`}
                    type="file"
                    name="image_4"
                    onChange={handleImageChange}
                  />
                </div>
                {ifImageExists.image_4 && blogData.image_4 ? (
                  <p className={`text-left pl-3 pb-3`}>
                    Current image:
                    <img
                      className={` size-20`}
                      src={blogData.image_4}
                      alt={blogData.image_4}
                    />
                  </p>
                ) : null}
              </div>
              <div className={`${classes.custom_quill_wrapper}`}>
                <Editor
                  name="paragraph_4"
                  onTextChange={handleEditorChange}
                  defaultValue={blogData.paragraph_4}
                />
              </div>
            </div>
            <div className={`${classes.img_p_section} border p-2 my-2.5`}>
              <div className={`flex flex-row`}>
                <div className="flex flex-col">
                  <InputLabel
                    className={`${classes.subtitles}`}
                    id="demo-simple-select-label"
                  >
                    Paragraph 5
                  </InputLabel>
                  <input
                    className={`border p-2 rounded mb-2 flex justify-center`}
                    type="file"
                    name="image_5"
                    onChange={handleImageChange}
                  />
                </div>
                {ifImageExists.image_5 && blogData.image_5 ? (
                  <p className={`text-left pl-3 pb-3`}>
                    Current image:
                    <img
                      className={` size-20`}
                      src={blogData.image_5}
                      alt={blogData.image_5}
                    />
                  </p>
                ) : null}
              </div>
              <div className={`${classes.custom_quill_wrapper}`}>
                <Editor
                  name="paragraph_5"
                  onTextChange={handleEditorChange}
                  defaultValue={blogData.paragraph_5}
                />
              </div>
            </div>
            <div className={`${classes.img_p_section} border p-2 my-2.5`}>
              <div className={`flex flex-row`}>
                <div className="flex flex-col">
                  <InputLabel
                    className={`${classes.subtitles}`}
                    id="demo-simple-select-label"
                  >
                    Paragraph 6
                  </InputLabel>
                  <input
                    className={`border p-2 rounded mb-2 flex justify-center`}
                    type="file"
                    name="image_6"
                    onChange={handleImageChange}
                  />
                </div>
                {ifImageExists.image_6 && blogData.image_6 ? (
                  <p className={`text-left pl-3 pb-3`}>
                    Current image:
                    <img
                      className={` size-20`}
                      src={blogData.image_6}
                      alt={blogData.image_6}
                    />
                  </p>
                ) : null}
              </div>
              <div className={`${classes.custom_quill_wrapper}`}>
                <Editor
                  name="paragraph_6"
                  onTextChange={handleEditorChange}
                  defaultValue={blogData.paragraph_6}
                />
              </div>
            </div>
            <div className={`${classes.img_p_section} border p-2 my-2.5`}>
              <div className={`flex flex-row`}>
                <div className="flex flex-col">
                  <InputLabel
                    className={`${classes.subtitles}`}
                    id="demo-simple-select-label"
                  >
                    Paragraph 7
                  </InputLabel>
                  <input
                    className={`border p-2 rounded mb-2 flex justify-center`}
                    type="file"
                    name="image_7"
                    onChange={handleImageChange}
                  />
                </div>
                {ifImageExists.image_7 && blogData.image_7 ? (
                  <p className={`text-left pl-3 pb-3`}>
                    Current image:
                    <img
                      className={` size-20`}
                      src={blogData.image_7}
                      alt={blogData.image_7}
                    />
                  </p>
                ) : null}
              </div>
              <div className={`${classes.custom_quill_wrapper}`}>
                <Editor
                  name="paragraph_7"
                  onTextChange={handleEditorChange}
                  defaultValue={blogData.paragraph_7}
                />
              </div>
            </div>
            <div className={`${classes.img_p_section} border p-2 my-2.5`}>
              <div className={`flex flex-row`}>
                <div className="flex flex-col">
                  <InputLabel
                    className={`${classes.subtitles}`}
                    id="demo-simple-select-label"
                  >
                    Paragraph 8
                  </InputLabel>
                  <input
                    className={`border p-2 rounded mb-2 flex justify-center`}
                    type="file"
                    name="image_8"
                    onChange={handleImageChange}
                  />
                </div>
                {ifImageExists.image_8 && blogData.image_8 ? (
                  <p className={`text-left pl-3 pb-3`}>
                    Current image:
                    <img
                      className={` size-20`}
                      src={blogData.image_8}
                      alt={blogData.image_8}
                    />
                  </p>
                ) : null}
              </div>
              <div className={`${classes.custom_quill_wrapper}`}>
                <Editor
                  name="paragraph_8"
                  onTextChange={handleEditorChange}
                  defaultValue={blogData.paragraph_8}
                />
              </div>
            </div>
            <div className={`${classes.img_p_section} border p-2 my-2.5`}>
              <div className={`flex flex-row`}>
                <div className="flex flex-col">
                  <InputLabel
                    className={`${classes.subtitles}`}
                    id="demo-simple-select-label"
                  >
                    Paragraph 9
                  </InputLabel>
                  <input
                    className={`border p-2 rounded mb-2 flex justify-center`}
                    type="file"
                    name="image_9"
                    onChange={handleImageChange}
                  />
                </div>
                {ifImageExists.image_9 && blogData.image_9 ? (
                  <p className={`text-left pl-3 pb-3`}>
                    Current image:
                    <img
                      className={` size-20`}
                      src={blogData.image_9}
                      alt={blogData.image_9}
                    />
                  </p>
                ) : null}
              </div>
              <div className={`${classes.custom_quill_wrapper}`}>
                <Editor
                  name="paragraph_9"
                  onTextChange={handleEditorChange}
                  defaultValue={blogData.paragraph_9}
                />
              </div>
            </div>
            <div className={`${classes.img_p_section} border p-2 my-2.5`}>
              <div className={`flex flex-row`}>
                <div className="flex flex-col">
                  <InputLabel
                    className={`${classes.subtitles}`}
                    id="demo-simple-select-label"
                  >
                    Paragraph 10
                  </InputLabel>
                  <input
                    className={`border p-2 rounded mb-2 flex justify-center`}
                    type="file"
                    name="image_10"
                    onChange={handleImageChange}
                  />
                </div>
                {ifImageExists.image_10 && blogData.image_10 ? (
                  <p className={`text-left pl-3 pb-3`}>
                    Current image:
                    <img
                      className={` size-20`}
                      src={blogData.image_10}
                      alt={blogData.image_10}
                    />
                  </p>
                ) : null}
              </div>
              <div className={`${classes.custom_quill_wrapper}`}>
                <Editor
                  name="paragraph_10"
                  onTextChange={handleEditorChange}
                  defaultValue={blogData.paragraph_10}
                />
              </div>
            </div>
          </section>
          <div className={classes.button_container}>
            <GeneralButton
              className="w-40"
              colour={"blue"}
              onSubmit={handleSubmit}
            >
              Submit
            </GeneralButton>
            {/* <GeneralButton
              className="w-40"
              colour={"blue"}
              onClick={handlePreviewClick}
            >
              Preview
            </GeneralButton> */}
          </div>
          {!createError && <p style={{ color: "green" }}>{blogData.success}</p>}
          {createError ? <p style={{ color: "red" }}>{createError}</p> : ""}
        </form>
      </div>
    </>
  );
};

export default EditBlogPage;
