import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Pagination, PaginationItem } from "@mui/material";
import states from "states-us";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

export const FosterTable = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 30;
  const [fosterList, setFosterList] = useState(data);
  const [localUserData, setLocalUserData] = useState(null);
  const userData = useSelector((state) => state.userData.userData);
  const [searchParam, setSearchParam] = useState("");
  const [sortCriteria, setSortCriteria] = useState("last_name");
  const [sortReversed, setSortReversed] = useState(true);
  const [expandRow, setExpandRow] = useState(null);
  const { width } = useWindowDimensions();

  const navigate = useNavigate();

  // Retrieve user data from Redux store
  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      setLocalUserData(JSON.parse(userData));
    }
  }, [userData]);

  // Calculate the current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Handle sorting
  const sortedFosterList = fosterList.sort((a, b) => {
    if (!sortReversed) {
      if (a[sortCriteria] < b[sortCriteria]) return -1;
      if (a[sortCriteria] > b[sortCriteria]) return 1;
    } else {
      if (a[sortCriteria] < b[sortCriteria]) return 1;
      if (a[sortCriteria] > b[sortCriteria]) return -1;
    }
    return 0;
  });

  // Filter based on search parameter
  const filteredFosterList = sortedFosterList.filter((foster) => {
    return (
      foster.first_name.toLowerCase().includes(searchParam.toLowerCase()) ||
      foster.last_name.toLowerCase().includes(searchParam.toLowerCase()) ||
      foster.city.toLowerCase().includes(searchParam.toLowerCase()) ||
      foster.state.toLowerCase().includes(searchParam.toLowerCase()) ||
      foster.country.toLowerCase().includes(searchParam.toLowerCase())
    );
  });

  const currentItems = filteredFosterList.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Pagination
  const totalPages = Math.ceil(filteredFosterList.length / itemsPerPage);

  const handleSearch = (e) => {
    setSearchParam(e.target.value);
    setCurrentPage(1); // Reset to first page on new search
  };

  const handleSort = (criteria) => {
    if (criteria === sortCriteria) {
      setSortReversed(!sortReversed);
    } else {
      setSortCriteria(criteria);
      setSortReversed(false);
    }
  };

  const renderItem = (item) => {
    if (item.type === "previous" || item.type === "next") {
      const isPrevious = item.type === "previous";
      let page = isPrevious
        ? Math.max(1, item.page - 1)
        : Math.min(totalPages, item.page + 1);
      return (
        <PaginationItem
          {...item}
          page={page}
          onClick={() => handleChange(null, page)}
        />
      );
    }
    return <PaginationItem {...item} />;
  };

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const convertStateAbbreviation = (stateAbbr) => {
    const fullState = states.find((s) => s.abbreviation === stateAbbr);
    if (fullState) {
      return fullState.name;
    }
    return stateAbbr;
  };

  const handleDropdown = (index) => {
    setExpandRow(expandRow === index ? null : index);
  };

  return (
    <div className="px-auto w-full flex flex-col justify-center items-center">
      <div className="flex items-center mb-5 gap-4 w-full">
        <div className="relative w-full">
          <input
            type="text"
            placeholder="Type to search by name, city, state, or country"
            value={searchParam}
            onChange={handleSearch}
            className="p-2 pl-8 border text-black border-gray-300 rounded-lg lg:w-2/3 h-10"
          />
        </div>
      </div>
      <table className="w-full lg:px-10 table-fixed border-separate border-spacing-y-2">
        {width > 768 ? (
          <>
            <thead>
              <tr>
                <th
                  className="text-sm font-medium text-gray-500 cursor-pointer"
                  onClick={() => handleSort("first_name")}
                >
                  First Name
                </th>
                <th
                  className="text-sm font-medium text-gray-500 cursor-pointer"
                  onClick={() => handleSort("last_name")}
                >
                  Last Name
                </th>
                <th
                  className="text-sm font-medium text-gray-500 cursor-pointer"
                  onClick={() => handleSort("city")}
                >
                  City
                </th>
                <th
                  className="text-sm font-medium text-gray-500 cursor-pointer"
                  onClick={() => handleSort("state")}
                >
                  State
                </th>
                <th
                  className="text-sm font-medium text-gray-500 cursor-pointer"
                  onClick={() => handleSort("country")}
                >
                  Country
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((foster, index) => (
                <tr
                  key={index}
                  className="bg-sky-50 mb-2 break-words hover:bg-gray-100"
                >
                  <td className="rounded-l-xl text-sm text-neutral-600 text-center p-2.5">
                    {foster.first_name}
                  </td>
                  <td className="text-sm text-neutral-600 text-center p-2.5">
                    {foster.last_name}
                  </td>
                  <td className="text-sm text-neutral-600 text-center p-2.5">
                    {foster.city}
                  </td>
                  <td className="text-sm text-neutral-600 text-center p-2.5">
                    {convertStateAbbreviation(foster.state)}
                  </td>
                  <td className="rounded-r-xl text-sm text-neutral-600 text-center p-2.5">
                    {foster.country}
                  </td>
                </tr>
              ))}
              {filteredFosterList.length <= 0 && (
                <tr>
                  <td colSpan="5" className="text-center p-4">
                    No fosters found!
                  </td>
                </tr>
              )}
            </tbody>
          </>
        ) : (
          <>
            <thead>
              <tr>
                <th className="text-sm text-left pl-4 font-medium text-black">
                  Name
                </th>
                <th className="text-sm text-left pl-4 font-medium text-black">
                  City
                </th>
                <th className="w-[10%]"></th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((foster, index) => (
                <tr
                  key={index}
                  className="bg-sky-50 mb-2 break-words cursor-pointer"
                  onClick={() => handleDropdown(index)}
                >
                  <td className="rounded-l-xl text-lg text-[#667085] font-bold p-2.5 text-left pl-4">
                    {foster.first_name} {foster.last_name}
                    {expandRow === index && (
                      <div className="flex flex-col my-4">
                        <div className="my-2">
                          <p className="text-sm text-[#667085]">City</p>
                          <p>{foster.city}</p>
                        </div>
                        <div className="my-2">
                          <p className="text-sm text-[#667085]">State</p>
                          <p>{convertStateAbbreviation(foster.state)}</p>
                        </div>
                        <div className="my-2">
                          <p className="text-sm text-[#667085]">Country</p>
                          <p>{foster.country}</p>
                        </div>
                      </div>
                    )}
                  </td>
                  {expandRow !== index && (
                    <td className="text-lg text-[#667085] font-bold p-2.5 text-left pl-4">
                      {foster.city}
                    </td>
                  )}
                  {expandRow === index ? (
                    <td className="bg-sky-50 mb-2 rounded-r-xl" align="center">
                      <svg
                        width="16"
                        height="9"
                        viewBox="0 0 16 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0.999528 9L-3.47717e-07 7.95484L7.33176 0.287717C7.51087 0.103191 7.75054 -2.82587e-07 8 -2.74042e-07C8.24946 -2.65496e-07 8.48913 0.103191 8.66824 0.287717L16 7.95485L14.9995 9L8 1.68092L0.999528 9Z"
                          fill="#7F7F7F"
                        />
                      </svg>
                    </td>
                  ) : (
                    <td className="bg-sky-50 mb-2 rounded-r-xl" align="center">
                      <svg
                        width="16"
                        height="9"
                        viewBox="0 0 16 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15.0005 1.21503e-07L16 1.04515L8.66824 8.71228C8.48913 8.89681 8.24946 9 8 9C7.75054 9 7.51087 8.89681 7.33176 8.71228L1.39656e-07 1.04515L1.00047 8.10373e-09L8 7.31908L15.0005 1.21503e-07Z"
                          fill="#7F7F7F"
                        />
                      </svg>
                    </td>
                  )}
                </tr>
              ))}
              {filteredFosterList.length <= 0 && (
                <tr>
                  <td colSpan="2" className="text-center p-4">
                    No fosters found!
                  </td>
                </tr>
              )}
            </tbody>
          </>
        )}
      </table>
      <nav className="overflow-x-auto mt-4 flex justify-end max-[768px]:justify-center">
        <Pagination
          sx={{
            ".MuiPaginationItem-root": {
              backgroundColor: "var(--light-grey)",
              borderRadius: "0",
            },
            ".Mui-selected": {
              backgroundColor: "var(--default-orange) !important",
              borderRadius: "0",
            },
          }}
          count={totalPages}
          page={currentPage}
          onChange={handleChange}
          className="flex list-none justify-center"
          color="primary"
          renderItem={renderItem}
        />
      </nav>
    </div>
  );
};
