import Spinner from "../../shared/LoadingSpinner/Spinner";
import { useEffect, useState } from "react";
import { fetchArticlesPagination } from "../../../api/Articles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import ArticlePage from "./ArticlesPage";
import { subscribeMarketingNewsletter } from "../../../api/SubscribeNewsletter";
import { validateEmail } from "../../shared/Utils/ValidateEmail";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import { lineBreaks } from "../../shared/Utils/LineBreaks";

function Articles() {
    const [articles, setArticles] = useState([]);
    const [page, setPage] = useState(1);
    const [loadMore, setLoadMore] = useState(false);
    const [searchParam, setSearchParam] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [articlePage, setArticlePage] = useState({});
    const [subscribe, setSubscribe] = useState(false);
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [emailSuccess, setEmailSuccess] = useState("");

    const getArticles = async (pageNumber, search) => {
        try {
          const response = await fetchArticlesPagination(pageNumber, search);

          if (pageNumber === 1) {
            setArticles(response.results);
          } else {
            setArticles([
                ...articles,
                ...response.results
            ])
          }
          setLoadMore(response.next);
        } catch (error) {
          console.log("Can't get articles!");
        }
      }

    useEffect(() => {
        getArticles(1, "");
    }, [])

    const handleLoadMore = () => {
        getArticles(page+1, searchParam);
        setPage(page+1);
    }

    const handleSearch = () => {
        getArticles(1, searchParam);
    }
    
    const calculateReadingTime = (text) => {
        const wordsPerMinute = 225;
        const words = text.replace(/<[^>]+>/g, "").split(/\s+/).length;
        const minutes = words / wordsPerMinute;
        return Math.ceil(minutes);
      };

    const handleOpenArticle = (articlePage) => {
        setIsOpen(true);
        setArticlePage(articlePage)
    }

    const handleCloseArticle = (articlePage) => {
        setIsOpen(false);
        setArticlePage(articlePage)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (email.trim() === "") {
          setEmailError("Please enter an email address.");
          return;
        }
        subscribeToNewsletter(email);
      };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        if (emailError) setEmailError("");
    };

    const subscribeToNewsletter = async (email) => {
        if (!validateEmail(email)) {
        throw new Error("Please enter a valid email address.");
        }

    const payload = {
      email: email,
      active: false,
    };

    try {
      await subscribeMarketingNewsletter(payload);
      setEmail("");
      setEmailError("");
      setEmailSuccess("Thanks! You're subscribed!")
    } catch (error) {
      let errorMessage = errorHandler(error);
      setEmailSuccess("");
      if (errorMessage.includes("email:")) {
        errorMessage = errorMessage.substring(7, errorMessage.length - 1);
      }
      const errorMessageWithBreaks = lineBreaks(errorMessage);

      setEmailError(errorMessageWithBreaks);
    }
  };

    return (
        <div className="m-0 p-4 w-full xl:w-4xl h-full">
            {isOpen ? (
                <ArticlePage article={articlePage} onClose={handleCloseArticle} />
            ) : (
                <>
                <div className="w-full rounded-xl bg-blue pt-8 pr-8 pl-16 mb-8 flex justify-between items-center">
                    <section className="flex flex-col bg-blue text-left gap-8">
                        <h1 className="text-white !text-5xl">Sponsor a Pet Talks</h1>
                        <p className="text-white !text-2xl">Check out the latest articles on various topics!</p>
                        <div className="flex flex-row justify-start items-end gap-4">
                            {subscribe ? (
                                <form
                                className="flex flex-col"
                                onSubmit={handleSubmit}
                            >
                                <input
                                    type="email"
                                    id="newsletter-email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={handleEmailChange}
                                    className="text-grey mb-2"
                                />
                                {emailError && (
                                    <p className="text-white font-medium">{emailError}</p>
                                )}
                                {emailSuccess && (
                                    <p className="text-white font-medium">{emailSuccess}</p>
                                )}
                                <button className="text-blue bg-white border border-blue px-8 py-2 rounded-md hover:bg-blue hover:text-white hover:border-white" type="submit">
                                    Subscribe →
                                </button>
                            </form>
                            ) : (
                                <button 
                                    onClick={() => setSubscribe(true)}
                                    className="text-blue bg-white border border-blue px-8 py-2 rounded-md hover:bg-blue hover:text-white hover:border-white">
                                    Subscribe
                                </button>
                            )}
                            {/* <button className="text-white border border-white bg-blue px-8 py-2 rounded-md hover:text-blue hover:bg-white">
                                <Link to="/">
                                    Find More
                                </Link>
                            </button> */}
                        </div>
                    </section>
                    <img 
                    className="h-96"
                    src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/smirking-dog.png" />
                </div>
                <div className="w-full rounded-xl bg-blue p-8">
                    <h1 className="text-white !text-3xl mb-4">Articles</h1>
                    <p className="text-white">Nonprofit Growth Guides, Strategies, and more</p>
                    <div className="">
                        <div className="flex flex-row mt-8 gap-4">
                            <div className="max-w-4xl w-full h-9 flex items-center m-0 border border-grey rounded-full overflow-hidden bg-white">
                                <FontAwesomeIcon className="cursor-default text-grey my-0 mx-2" icon={faMagnifyingGlass} />
                                <input
                                    type="text"
                                    placeholder="Type to search by title"
                                    value={searchParam}
                                    onChange={(e) => setSearchParam(e.target.value)}
                                    className="flex-1 border-none outline-none"
                                />
                            </div>
                            <button 
                                className="flex justify-center item-center mx-auto h-9 px-4 py-1.5 bg-orange hover:bg-orange-dark1 shadow-xl rounded-3xl text-white font-bold text-base" 
                                onClick={handleSearch}>Search</button>
                        </div>
                            { articles ? (
                                <>
                                    <div className="flex flex-col justify-center items-center gap-8 mt-16 w-full">
                                        {articles.map((article) => (
                                                <div 
                                                    key={article.id} 
                                                    className="flex flex-col bg-white rounded-2xl p-7 mb-4 w-full cursor-pointer"
                                                    onClick={() => handleOpenArticle(article)}>
                                                    <img className="w-4xl h-96 object-cover mb-4" src={article.image} />
                                                    <div className="flex flex-col text-left">
                                                    <p className="!text-md text-blue !font-semibold">{article.title}</p>
                                                    <p className="!text-xs text-grey">Published {new Date(article.created_at).toLocaleDateString("en-US", {
                                                        month: "short",
                                                        day: "numeric",
                                                        year: "numeric",
                                                        })} {" · "} {calculateReadingTime(article.content) + '+ minute read'}</p>
                                                    </div>
                                                </div>
                                        ))}
                                    </div>
                                    {loadMore && (
                                        <button 
                                            onClick={() => handleLoadMore()}
                                            className="flex justify-center item-center mx-auto mt-16 px-4 py-2 bg-white hover:bg-blue-dark1 shadow-xl rounded-3xl text-blue hover:text-white font-bold text-base "
                                            >Load More</button>
                                    )}
                                </>
                            ) : (
                                <div className="flex justify-center items-center">
                                    <Spinner size={30} />
                                </div>
                            )}
                        </div>
                </div>
                </>
            )}
            
            </div>
    )
}

export default Articles;