import { InputLabel, TextField } from "@mui/material";
import { useState, useEffect } from "react";
import GeneralButton from "../../shared/Buttons/GeneralButton";
import updateSeshStorage from "../../shared/Utils/updateSeshStorage";
import Spinner from "../../shared/LoadingSpinner/Spinner";
import { MAXFILESIZE } from "../../../constants";
import { createArticle, updateArticle } from "../../../api/Articles";
import Editor from "../../Dashboard/CampaignModal/Editor/Editor";
import populateFormData from "../../shared/Utils/populateFormData";
import handleEditorChange from "../../shared/Utils/handleEditorChange";
import PreviewArticle from "./PreviewArticle";

const ArticleForm = ({ data, type, previewState = null }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [articleData, setArticleData] = useState({
    ...data,
    error: "",
    success: "",
  });
  const { previewOpen, setPreviewOpen } =
    previewState === null ? {} : previewState;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!articleData.title) {
      setArticleData((prevState) => ({
        ...prevState,
        error: "Please add an article title",
        success: "",
      }));
    } else if (!articleData.content) {
      setArticleData((prevState) => ({
        ...prevState,
        error: "Please add some article content",
        success: "",
      }));
    } else {
      try {
        setIsLoading(true);
        let formData;

        // Conditional check if image is a File object
        // If image is a File, send to backend
        // If not, don't send an image file (stops multiple pictures in the database)
        if (typeof articleData.image === 'string') {
          delete articleData.image;
        }
        formData = populateFormData(articleData);

        if (type === "create") {
          await createArticle(formData);
          setArticleData((prevState) => ({
            ...prevState,
            success: "Blog created successfully!",
            error: "",
          }));
        } else {
          formData.delete("created_at");

          await updateArticle(Number(articleData.id), formData);
          setArticleData((prevState) => ({
            ...prevState,
            success: "Blog updated successfully!",
            error: "",
          }));
        }

        sessionStorage.clear();
      } catch {
        setArticleData((prevState) => ({
          ...prevState,
          success: "",
          error:
            "An internal server error occurred. Please contact us for assistance at sponsorapet.org/contact-us",
        }));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    if (files.length > 0) {
      // error handling if removed file
      if (files[0]["size"] > MAXFILESIZE) {
        setArticleData((prevState) => ({
          ...prevState,
          error: "File size is too large (Must be under 3 MB).",
          success: "",
        }));
      } else {
        setArticleData((prevState) => ({
          ...prevState,
          image: files[0],
          error: "", // Clear any previous error
        }));
      }
    }
  };

  useEffect(() => {
    if (articleData.title || articleData.content) {
      setArticleData((prevState) => ({
        ...prevState,
        error: "",
      }));
    }
  }, [articleData.title, articleData.content]);

  if (type === "create" && previewOpen) {
    return (
      <PreviewArticle
        article={articleData}
        onClose={() => setPreviewOpen(false)}
      />
    );
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col items-center w-full py-3"
    >
      <div>
        <InputLabel id="demo-simple-select-label">Cover Image</InputLabel>
        <input
          className={`border p-2 w-fit mb-4`}
          type="file"
          name="image"
          onChange={handleImageChange}
        />
      </div>
      <TextField
        className="w-[30vw]"
        id="title"
        label="title"
        name="title"
        onChange={(e) => updateSeshStorage(e, setArticleData)}
        value={articleData.title || ""}
      />
      <div className={`border p-2 mt-5 w-full`}>
        <InputLabel id="demo-simple-select-label">Content</InputLabel>
        <Editor
          name={"content"}
          onTextChange={(content, delta, source, quill) =>
            handleEditorChange(
              content,
              delta,
              source,
              quill,
              "content",
              setArticleData
            )
          }
          defaultValue={articleData.content}
        />
      </div>
      <div className="space-x-3">
        <GeneralButton
          className="w-40 mt-10"
          colour={"blue"}
          onSubmit={handleSubmit}
          disabled={isLoading}
        >
          Submit
        </GeneralButton>
        {type === "create" && (
          <GeneralButton
            className="w-40 mt-10"
            colour={"blue"}
            onClick={(e) => {
              e.preventDefault();
              setPreviewOpen(true);
            }}
          >
            Preview
          </GeneralButton>
        )}
      </div>
      {isLoading && (
        <div className="flex justify-center items-center">
          <Spinner size={30} />
        </div>
      )}
      {articleData.success ? (
        <p className=" text-green-500">{articleData.success}</p>
      ) : (
        ""
      )}
      {articleData.error ? (
        <p className=" text-red-500">{articleData.error}</p>
      ) : (
        ""
      )}
    </form>
  );
};

export default ArticleForm;
