import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faStar as faSolidStar,
} from "@fortawesome/free-solid-svg-icons";
import { urlifyName } from "../../shared/Utils/PageUrl";
import { getNonprofits } from "../../../api/Nonprofits";
import { removeFavorite } from "../../../api/Favorite";
import { useSelector } from "react-redux";
import Spinner from "../../shared/LoadingSpinner/Spinner";
import { cardTitleExcerpt } from "../../shared/Utils/CardTitleExcerpt";
import { parseDescription } from "../../shared/Utils/ParseDescription";

export default function Favorites() {
  const [localUserData, setLocalUserData] = useState(null);
  const [npData, setNpData] = useState(null);
  const [clickedFavorite, setClickedFavorite] = useState(false);
  const [nonprofits, setNonprofits] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userData.userData);

  const npExcerpt = (plainText) => {
    let excerpt = plainText.substring(0, 50);
    // Ensure the last character isn't punctuation before adding ellipsis
    excerpt = excerpt.replace(/[.,;!?]$/, "") + "...";
    return excerpt;
  };
  
  // Retrieve user data from localStorage
  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      setLocalUserData(JSON.parse(userData));
    }
  }, [userData, clickedFavorite]);

  // Retrieve NP and campaign data
  useEffect(() => {
    if (localUserData && localUserData.favorite_nonprofits) {
      const fetchNonprofits = async () => {
        try {
          const response = await getNonprofits();

          // NP's mission statement is a string of a <p> tag
          // Below useEffect extracts the inner text out
          const tempDiv = document.createElement("div");
          response.forEach((np) => {
            tempDiv.innerHTML = np["mission_stmt"];
            const text = tempDiv.textContent || tempDiv.innerText || "";
            np["mission_stmt"] = text.trim();
          });

          setNonprofits(
            response.filter((nonprofit) =>
              localUserData.favorite_nonprofits.includes(nonprofit.id)
            )
          );
        } catch (error) {
          console.error("Error: ", error);
        }
      };
      fetchNonprofits();
    }
  }, [localUserData]);

  if (isLoading) {
    return (
      <div className="flex flex-row ml-auto mt-60 w-full h-full justify-center">
        <Spinner />
      </div>
    );
  }

  const handleRemoveFavorite = async (id) => {
    if (!localUserData) return;
    try {
      await removeFavorite({ nonprofit_id: id });
      setClickedFavorite(!clickedFavorite);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCardClick = (orgName, id) => {
    navigate(`/non-profit/${urlifyName(orgName)}/${id}`);
  };

  return (
    <div
      className="m-0 lg:ml-14 lg:mt-10 p-4 w-full xl:w-4xl h-full"
    >
      <div>
        <h2 className="font-semibold text-3xl text-orange mb-8">
          Favorite Non-Profits
        </h2>
        {nonprofits && localUserData ? (
          <div
            className="p-0 justify-center max-w-full w-11/12 flex flex-wrap gap-12 mx-auto mt-8 mb-0 lg:justify-left"
          >
            {nonprofits.map((item, index) => {
              return (
                <Link
                  to={`/non-profit/${urlifyName(item.org_name)}/${item.id}`}
                  key={index}
                >
                  <div 
                    className="relative flex flex-col w-72 shrink-0 grow-0 rounded-lg shadow-cardShadow hover:shadow-cardHoverShadow overflow-hidden p-5 gap-3 h-96 text-left bg-white text-black select-none"
                    key={item.org_name}>
                    <div className="flex w-full text-left flex-row">
                      {item.address
                        ? `${item.address.city}, ${item.address.state}`
                        : ""}
                      {localUserData &&
                      localUserData.favorite_nonprofits.includes(item.id) ? (
                        <FontAwesomeIcon
                          className="ml-auto text-orange cursor-pointer"
                          icon={faSolidStar}
                          onClick={() => {
                            handleRemoveFavorite(item.id);
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    {item.logo ? (
                      <img
                        onClick={() => handleCardClick(item.org_name, item.id)}
                        src={item.logo}
                        alt={item.logo}
                        className="w-full h-32 my-0 mx-auto object-contain overflow-hidden"
                      />
                    ) : (
                      <img alt="" />
                    )}
                    <div className="flex flex-col w-full text-left flex-1 relative">
                      <h3 className="w-full m-0 text-blue font-fingerpaint font-semibold text-2xl">
                        {cardTitleExcerpt(item.org_name, 35)}
                      </h3>
                      {parseDescription === item.mission_stmt ? (
                        <p className="mx-0 mt-2 mb-3 flex-1">{npExcerpt(item.mission_stmt)}</p>
                      ) : (
                        <div
                          className="flex flex-col w-full text-left flex-1"
                          dangerouslySetInnerHTML={{
                            __html: npExcerpt(
                              parseDescription(item.mission_stmt)
                            ),
                          }}
                        />
                      )}
                      <FontAwesomeIcon
                        className="absolute bottom-3 right-2 text-orange text-2xl"
                        icon={faArrowRight}
                      />
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        ) : (
          ""
        )}
        {nonprofits.length <= 0 ? (
          <>
            <p>Add your favorite non-profits here!</p>
            <Link
              className="bg-blue w-40 h-8 inline-flex justify-center items-center text-center my-0 mx-auto border-none rounded text-white cursor-pointer text-base font-inherit no-underline"
              to={"/non-profit"}
            >
              Go to Non-Profits
            </Link>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
