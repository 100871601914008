import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { changePassword } from "../../../api/ChangePassword";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import { lineBreaks } from "../../shared/Utils/LineBreaks";

export default function ChangePasswordModal({ close }) {
  const [formData, setFormData] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [errorMsg, setErrorMsg] = useState({ error: "", new: false });
  const [feErrorMsg, setFEErrorMsg] = useState("");
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMsg({ error: "", new: false });
    setFEErrorMsg("");
    setIsPasswordChanged(false);

    if (
      !formData.old_password ||
      !formData.new_password ||
      !formData.confirm_password
    ) {
      setFEErrorMsg("Fill all fields.");
      return;
    }
    if (formData.new_password !== formData.confirm_password) {
      setFEErrorMsg("New passwords must match.");
      return;
    }
    try {
      await changePassword({
        old_password: formData.old_password,
        new_password: formData.new_password,
      });
      setIsPasswordChanged(true);
      setErrorMsg({
        error: "",
        new: false,
      });
    } catch (error) {
      let errorStr = errorHandler(error);
      if (errorStr.includes("new_password")) {
        errorStr = errorStr.substring(14, errorStr.length - 1);
        const errorMessageWithBreaks = lineBreaks(errorStr);
        setErrorMsg({
          error: errorMessageWithBreaks,
          new: true,
        });
      } else if (errorStr.includes("error:")) {
        errorStr = errorStr.substring(7, errorStr.length - 1);
        const errorMessageWithBreaks = lineBreaks(errorStr);
        setErrorMsg({
          error: errorMessageWithBreaks,
          new: false,
        });
      } else {
        const errorMessageWithBreaks = lineBreaks(errorStr);

        setErrorMsg({
          error: errorMessageWithBreaks,
          new: false,
        });
      }
    }
  };

  return (
    <div className="modal-background">
      <div className="m-auto max-w-full max-h-full lg:w-5/12 w-full h-fit bg-white opacity-100 overflow-hidden flex flex-col p-4 rounded-3xl">
        <FontAwesomeIcon
          icon={faX}
          onClick={close}
          className="cursor-pointer ml-auto text-grey"
        />
        <h2 className="text-2xl font-semibold mb-4 text-orange">Change Password</h2>
        <form onSubmit={handleSubmit} className="flex flex-col mt-8 mb-16 mx-0 w-100%">
          <label className="text-sm font-medium text-stone-500 mb-4 text-left">
            Old Password
            <br />
            <input
              type="password"
              name="old_password"
              value={formData.old_password}
              onChange={handleInputChange}
              className="py-4 px-2 border text-black font-normal border-stone-300 rounded-lg disabled:bg-neutral-50 w-full border-box h-8 my-2 mx-0"
            />
          </label>
          {errorMsg.error && !errorMsg.new ? (
            <label>
              {errorMsg.error}
            </label>
          ) : (
            ""
          )}
          <label className="text-sm font-medium text-stone-500 mb-4 text-left">
            New Password
            <br />
            <input
              type="password"
              name="new_password"
              value={formData.new_password}
              onChange={handleInputChange}
              className="py-4 px-2 border text-black font-normal border-stone-300 rounded-lg disabled:bg-neutral-50 border-box h-8 my-2 mx-0"
            />
          </label>
          {errorMsg.error && errorMsg.new ? (
            <label className="!text-red-500">
              {errorMsg.error}
            </label>
          ) : (
            ""
          )}
          <label className="text-sm font-medium text-stone-500 mb-4 text-left">
            Confirm New Password
            <br />
            <input
              type="password"
              name="confirm_password"
              value={formData.confirm_password}
              onChange={handleInputChange}
              className="py-4 px-2 border text-black font-normal border-stone-300 rounded-lg disabled:bg-neutral-50 border-box h-8 my-2 mx-0"
            />
          </label>
          <label className="!text-red-500">{feErrorMsg}</label>
          {isPasswordChanged && <p>Password changed successfully.</p>}
          <button 
            onClick={handleSubmit}
            className="bg-blue hover:bg-blue-dark1 !font-semibold px-4 py-2 text-white rounded-3xl text-base w-fit h-9 inline-flex justify-center items-center mx-auto mt-4 border-0 cursor-pointer disabled:cursor-default disabled:bg-grey"
          >Submit</button>
        </form>
      </div>
    </div>
  );
}
