import { useEffect, useState } from "react";
import { urlifyName } from "../../shared/Utils/PageUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faMagnifyingGlass,
  faStar as faSolidStar,
  faUsers,
  faPaw,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { faStar as faRegularStar } from "@fortawesome/free-regular-svg-icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getPaginatedNonprofits } from "../../../api/Nonprofits";
import { addFavorite, removeFavorite } from "../../../api/Favorite";
import { cardTitleExcerpt } from "../../shared/Utils/CardTitleExcerpt";
import { parseDescription } from "../../shared/Utils/ParseDescription";
import { useSelector } from "react-redux";
import { extractMissionStatement } from "../../shared/Utils/ExtractMissionStatement";
import Spinner from "../../shared/LoadingSpinner/Spinner";
import { states } from "states-us";

export default function NonprofitSearch() {
  const [nonprofits, setNonprofits] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParam, setSearchParam] = useState(
    searchParams.get("search") || ""
  );
  const [localUserData, setLocalUserData] = useState(null);
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userData.userData);
  const [page, setPage] = useState(1);
  const [IfDataExists, setIfDataExists] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [stateParam, setStateParam] = useState("");

  const npExcerpt = (plainText) => {
    const mission_stmt = extractMissionStatement(plainText, false);
    let excerpt = mission_stmt.substring(0, 50);

    // Ensure the last character isn't punctuation before adding ellipsis
    excerpt = excerpt.replace(/[.,;!?]$/, "") + "...";
    return excerpt;
  };

  // getting list of partners from api
  const getAllNonprofits = async (page, searchParam, stateParam) => {
    try {
      const response = await getPaginatedNonprofits(
        page,
        searchParam,
        stateParam
      );

      if (response) {
        setIsLoading(false);
      }

      if (page === 1) {
        setNonprofits(response.results);
      } else {
        setNonprofits([...nonprofits, ...response.results]);
      }
      if (!response.next) {
        setIfDataExists(false);
      } else {
        setIfDataExists(true);
      }
    } catch (error) {
      console.error(`Could not get nonprofits`, error);
    }
  };

  useEffect(() => {
    getAllNonprofits(page, "", "");
  }, []);

  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      setLocalUserData(JSON.parse(userData));
    }
  }, [userData]);

  // creating input callback for SEARCH bar
  const handleSearch = () => {
    setPage(1);
    getAllNonprofits(1, searchParam, stateParam);
  };

  const handleLoadMoreButton = (e) => {
    e.preventDefault();
    getAllNonprofits(page + 1, searchParam, stateParam);
    setPage(page + 1);
  };

  const handleAddFavorite = async (id) => {
    if (!localUserData) return;
    try {
      await addFavorite({ nonprofit_id: id });
      const i = localUserData.favorite_nonprofits.indexOf(id);
      if (i < 0) {
        const newFavorites = [...localUserData.favorite_nonprofits, id];
        setLocalUserData({
          ...localUserData,
          favorite_nonprofits: newFavorites,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemoveFavorite = async (id) => {
    if (!localUserData) return;
    try {
      await removeFavorite({ nonprofit_id: id });
      const i = localUserData.favorite_nonprofits.indexOf(id);
      if (i >= 0) {
        const newFavorites = localUserData.favorite_nonprofits.filter(
          (favId) => favId !== id
        );
        setLocalUserData({
          ...localUserData,
          favorite_nonprofits: newFavorites,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleStarClick = (event, isFavorite, id) => {
    event.stopPropagation();
    event.preventDefault();
    if (isFavorite) {
      handleRemoveFavorite(id);
    } else {
      handleAddFavorite(id);
    }
  };

  const handleStateChange = (e) => {
    setStateParam(e.target.value);
    setPage(1);
    getAllNonprofits(1, searchParam, e.target.value);
  };

  return (
    <div className="mb-8">
      <div className="sm:grow flex flex-col justify-center items-center w-full !my-0 pt-40 bg-blue">
        <h1 className="text-white">Non-Profits</h1>
        <div className="flex flex-col lg:flex-row items-end my-8 mx-auto gap-4 md:w-[60vw]">
          <div className="max-w-4xl min-w-96 max-w-96:min-w-72 w-full h-9 flex flex-1 items-center m-0 border border-grey rounded-full overflow-hidden bg-white">
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              className="text-grey ml-3 mr-2 text-xs"
            />
            <input
              type="text"
              placeholder="Type to search by name, location, etc."
              value={searchParam}
              onChange={(e) => setSearchParam(e.target.value)}
              className="flex-1 border-none outline-none text-xs"
            />
            <FontAwesomeIcon
              icon={faLocationDot}
              className="text-grey-dark1 ml-2 mr-2 pl-2 border-l border-l-grey-dark1"
            />
            <select
              className="py-2 mr-2 cursor-pointer text-grey-dark1 text-xs bg-white"
              value={stateParam}
              onChange={handleStateChange}
            >
              <option value="" disabled hidden>
                States
              </option>
              {states.map((state) => (
                <option key={state.abbreviation} value={state.abbreviation}>
                  {state.name}
                </option>
              ))}
            </select>
          </div>
          <button
            className="flex justify-center items-center mx-auto h-9 px-8 py-1.5 bg-orange hover:bg-orange-dark1 shadow-md rounded-3xl text-white font-semibold text-sm"
            onClick={handleSearch}
          >
            SEARCH
          </button>
        </div>
      </div>

      <div className="p-0 justify-center max-w-full w-11/12 flex flex-wrap gap-12 mx-auto mt-8 mb-0 lg:justify-left lg:py-0 lg:px-20">
        {!isLoading ? (
          nonprofits.map((item, index) => (
            <Link
              to={`/non-profit/${urlifyName(item.org_name)}/${item.id}`}
              key={index}
            >
              <div
                className="relative flex flex-col w-72 shrink-0 grow-0 rounded-lg shadow-cardShadow hover:shadow-cardHoverShadow overflow-hidden p-5 gap-3 h-96 text-left bg-white text-black select-none"
                key={item.org_name}
              >
                <div className="flex w-full text-left flex-row">
                  {item.address
                    ? `${item.address.city}, ${item.address.state}`
                    : ""}
                  {localUserData ? (
                    localUserData.favorite_nonprofits.includes(item.id) ? (
                      <FontAwesomeIcon
                        icon={faSolidStar}
                        onClick={(e) => handleStarClick(e, true, item.id)}
                        className="ml-auto cursor-pointer text-orange"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faRegularStar}
                        onClick={(e) => handleStarClick(e, false, item.id)}
                        className="ml-auto cursor-pointer text-orange"
                      />
                    )
                  ) : null}
                </div>
                {item.logo ? (
                  <img
                    src={item.logo}
                    alt={`Logo of ${item.org_name}`}
                    className="w-full h-32 my-0 mx-auto object-contain overflow-hidden"
                  />
                ) : null}
                <div className="flex flex-col w-full text-left flex-1 relative">
                  <h3 className="w-full m-0 text-blue font-fingerpaint font-semibold text-2xl">
                    {cardTitleExcerpt(item.org_name, 35)}
                  </h3>
                  {parseDescription === item.mission_stmt ? (
                    <p className="mx-0 mt-2 mb-3 flex-1">
                      {npExcerpt(item.mission_stmt)}
                    </p>
                  ) : (
                    <div
                      className="flex flex-col w-full text-left flex-1"
                      dangerouslySetInnerHTML={{
                        __html: npExcerpt(parseDescription(item.mission_stmt)),
                      }}
                    />
                  )}
                  <FontAwesomeIcon
                    className="absolute bottom-3 right-2 text-orange text-2xl"
                    icon={faArrowRight}
                  />
                </div>
              </div>
            </Link>
          ))
        ) : (
          <div className="flex flex-row mx-auto mt-16 w-full pb-24 justify-center">
            <Spinner />
          </div>
        )}
      </div>
      {!isLoading && IfDataExists && (
        <button
          className="flex justify-center item-center mx-auto mt-16 px-4 py-2 bg-blue hover:bg-blue-dark1 shadow-xl rounded-3xl text-white font-bold text-base "
          onClick={handleLoadMoreButton}
        >
          Load More
        </button>
      )}
    </div>
  );
}
