const updateSeshStorage = (e, setData) => {
  const { name, value } = e.target;

  setData((prevState) => ({
    ...prevState,
    [name]: value,
  }));

  sessionStorage.setItem(name, value);

  return { name, value };
};

export default updateSeshStorage;
