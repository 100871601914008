import React, { useEffect, useState } from "react";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import { Box, Card, CardContent, Alert, Typography } from "@mui/material";
import {
  affiliateSubscriptionUpdate,
  createStripeSubscription,
} from "../../../api/Affiliate";

const plans = [
  {
    price: "10",
    description: "8% Commission on PetStar donations",
    priceId: "priceId_for_8%",
    commission: 8,
  },
  {
    price: "25",
    description: "12% Commission on PetStar donations",
    priceId: "priceId_for_12%",
    commission: 12,
  },
  {
    price: "50",
    description: "15% Commission on PetStar donations",
    priceId: "priceId_for_15%",
    commission: 15,
  },
  {
    price: "100",
    description: "20% Commission on PetStar donations",
    priceId: "priceId_for_20%",
    commission: 20,
  },
];

const PetStarTab = () => {
  const [generalError, setGeneralError] = useState("");
  const [localUserData, setLocalUserData] = useState(null);
  const [commissionRate, setCommissionRate] = useState(null);
  const [monthlyAmount, setMonthlyAmount] = useState(null);
  const [ifUnsubscribe, setIfUnsubscribe] = useState(false);
  const [ifConfirm, setIfConfirm] = useState(false);
  const [buttonConfirm, setButtonConfirm] = useState(null);
  const [confirmUnsubscribeButton, setConfirmUnsubscribeButton] = useState(false);

  const AMOUNTS = {
    "5.00": "0",
    "8.00": "10",
    "12.00": "25",
    "15.00": "50",
    "20.00": "100",
  };

  useEffect(() => {
    const fetchData = async () => {
      const userDataString = localStorage.getItem("userData");

      if (userDataString) {
        const userData = JSON.parse(userDataString);
        setLocalUserData(userData);
        setCommissionRate(Math.round(userData.commission_rate));

        // If user is not currectnly subscribing
        if (userData.commission_rate === "0.00") {
          setIfUnsubscribe(true);
        }

        setMonthlyAmount(AMOUNTS[userData.commission_rate]);
      }
    };

    fetchData();
  }, []);

  // Submits the new plan
  const submitUpdate = async (priceId) => {
    const updateData = {
      new_price_id: priceId,
    };

    try {
      const data = await affiliateSubscriptionUpdate(
        localUserData.id,
        updateData
      );
      return data.response;
    } catch (error) {
      console.error("Error during update:", error);
      setGeneralError(errorHandler(error));
    }
  };

  // Sets state for a new plan
  const handlePlanClick = async (index) => {
    const selectedPlan = plans[index];
    setCommissionRate(selectedPlan.commission);
    setMonthlyAmount(selectedPlan.price);
    setIfUnsubscribe(false);
    setIfConfirm(false);

    if (localUserData.stripe_customer_id === null || localUserData.commission_rate === "5.00") {
      try {
        const response = await createStripeSubscription(localUserData.id, {
          price_Id: selectedPlan.priceId,
          amount: parseInt(selectedPlan.price),
        });
        if (response.redirect_url) {
          window.location.href = response.redirect_url;
        }
      } catch (error) {
        const errorMessage =
          typeof error === "string" ? error : errorHandler(error);
        setGeneralError(errorMessage);
        console.error("Error: ", error);
        return;
      }
    }

    await submitUpdate(selectedPlan.priceId);
  };

  const deleteSubscription = async () => {
    setGeneralError("");

    try {
      const deleteData = {
        new_price_id: "unsubscribe",
      };

      const data = await affiliateSubscriptionUpdate(
        localUserData.id,
        deleteData
      );
      setIfUnsubscribe(true);
      setConfirmUnsubscribeButton(false);
      return data.response;
    } catch (error) {
      const errorMessage = errorHandler(error);
      setGeneralError(errorMessage);
    }
  };

  const handleUnsubscribe = (e) => {
    e.preventDefault();

    deleteSubscription();
  };

  return (
    <div className="m-0 lg:ml-14 lg:mt-10 p-4 w-full xl:w-4xl h-full border">
      <div>
        <h1 className="text-orange">Current Plan</h1>

        {ifUnsubscribe ? (
          <>
            <p>You're currently not subscribed.</p>
            <p>To subscribe, select a plan below</p>
          </>
        ) : (
          <>
            <p className="font-bold">Donation: ${monthlyAmount}/month</p>
            <p className="font-bold">Commission Rate: {commissionRate}%</p>
            <p>
              To change your commission rate, select a monthly donation plan
              below
            </p>
          </>
        )}
      </div>
      <div>
        <h1 className="text-orange">Other Plans</h1>
        <p>
          Changing your current plan will automatically modify your monthly
          payment
        </p>
        <Box className="flex space-x-4 overflow-x-auto">
          {plans.map((plan, index) => (
            <Card
              key={index}
              className="border border-zinc-100 rounded shadow-petstarBox"
            >
              <CardContent
                className="flex flex-col justify-between h-100"
              >
                <Typography variant="h5" component="h2">
                  ${plan.price}
                </Typography>
                <Typography variant="caption">{plan.description}</Typography>
                {ifConfirm && buttonConfirm === index ? (
                  <button
                    className="w-20 bg-blue m-0 mx-auto mb-2 flex justify-center border-none rounded cursor-pointer text-base p-2 text-white"
                    onClick={() => handlePlanClick(index)}
                  >
                    Confirm?
                  </button>
                ) : (
                  <button
                    className="w-20 bg-blue m-0 mx-auto mb-2 flex justify-center border-none rounded cursor-pointer text-base p-2 text-white"
                    onClick={() => {
                      setIfConfirm(true);
                      setButtonConfirm(index);
                    }}
                  >
                    Select
                  </button>
                )}
              </CardContent>
            </Card>
          ))}
        </Box>
        {localUserData?.commission_rate !== "5.00" && (
          <div>
            {confirmUnsubscribeButton ? (
              <button
                className="w-40 bg-blue flex justify-center p-2 text-base text-white border-none rounded cursor-pointer !mt-4 mx-auto"
                onClick={(e) => handleUnsubscribe(e)}
              >
                Confirm?
              </button>
            ) : (
              <button
                className="w-40 bg-blue flex justify-center p-2 text-base text-white border-none rounded cursor-pointer !mt-4 mx-auto"
                onClick={() => setConfirmUnsubscribeButton(true)}
              >
                Unsubscribe
              </button>
            )}
            <div className="flex flex-col items-center justify-center w-full mt-4">
              <Alert severity="warning">
                If you unsubscribe, your commission rate will be 5%
              </Alert>
            </div>
          </div>
        )}

        {generalError && (
          <Alert severity="warning">
            {typeof generalError === "string"
              ? generalError
              : "An unexpected error occurred"}
          </Alert>
        )}
      </div>
    </div>
  );
};

export default PetStarTab;
