import axios from "axios";
import { urlifyName } from "../components/shared/Utils/PageUrl";

const API_URL = process.env.REACT_APP_API_URL;

export const getNonprofits = async () => {
  try {
    const response = await axios.get(`${API_URL}/nonprofits/`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Login error:", error.response);
      throw error;
    }
  }
};

// API Call to get all NP States
export const getNonprofitsState = async () => {
  try {
    const response = await axios.get(`${API_URL}/nonprofits/states/`);
    return response;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("get campaign error:", error.response);
      throw error;
    }
  }
};

export const getPaginatedNonprofits = async (page, searchParam, stateParam) => {
  const params = {
    page,
    search: searchParam,
    state: stateParam,
  };
  try {
    const response = await axios.get(`${API_URL}/nonprofits/paginate/`, {
      params,
    });
    return response.data;
  } catch (error) {
    // Handle or throw error
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("get nonprofit error:", error.response);
      throw error;
    }
  }
};

export const getNonprofit = async (id, urlName) => {
  const response = await getNonprofits();
  if (!response) return null;
  for (let i in response) {
    if (
      urlifyName(response[i].org_name) === urlName &&
      response[i].id === parseInt(id)
    )
      return response[i];
  }
  return null;
};

export const getNonprofitById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/nonprofits/${id}`);
    if (!response) return null;

    return response.data;
  } catch (error) {
    // Handle or throw error
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("get nonprofit error:", error.response);
      throw error;
    }
  }
};
