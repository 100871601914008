import { TextField, Button } from "@mui/material";
import { useState } from "react";
import { blue } from "@mui/material/colors";
import CreateArticle from "./CreateArticle";
import ArticleList from "./ArticleList";

const Resources = () => {
  const [searchInput, setSearchInput] = useState("");
  const [createArticle, setCreateArticle] = useState(false);
  const [editArticle, setEditArticle] = useState(false);

  return createArticle ? (
    <CreateArticle setCreateArticle={() => setCreateArticle(false)} />
  ) : (
    <>
      {!editArticle && (
        <>
          <TextField
            type="text"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            placeholder="Search for an article..."
            size="small"
            className="w-1/2"
          />
          <div className="my-4">
            <Button
              variant="contained"
              onClick={() => setCreateArticle(true)}
              style={{ backgroundColor: blue[500], color: "white" }}
              size="large"
            >
              Create a new article
            </Button>
          </div>
        </>
      )}
      <ArticleList searchInput={searchInput} setEditArticle={setEditArticle} />
    </>
  );
};

export default Resources;
