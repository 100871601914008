import React, { useState, useEffect } from "react";
import { customerPortal } from "../../../api/PetstarSuccess";
import { useSelector } from "react-redux";
import {
  Alert,
  Box,
  Card,
  CardContent,
  ListItemSecondaryAction,
  TextField,
  Typography,
} from "@mui/material";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import { petstarSubscriptionUpdate } from "../../../api/PetstarSubscribe";

const plans = [
  {
    price: "10",
    description: "$10/month Donation to PetStar",
    priceId: "priceId_for_10",
  },
  {
    price: "25",
    description: "$25/month Donation to PetStar",
    priceId: "priceId_for_25",
  },
  {
    price: "35",
    description: "$35/month Donation to PetStar",
    priceId: "priceId_for_35",
  },
  {
    price: "50",
    description: "$50/month Donation to PetStar",
    priceId: "priceId_for_50",
  },
  {
    price: "100",
    description: "$100/month Donation to PetStar",
    priceId: "priceId_for_100",
  },
];

function Subscription() {
  const [generalError, setGeneralError] = useState("");
  const [localUserData, setLocalUserData] = useState(null);
  const [monthlyAmount, setMonthlyAmount] = useState(null);
  const [ifUnsubscribe, setIfUnsubscribe] = useState(false);
  const [ifConfirm, setIfConfirm] = useState(false);
  const [buttonConfirm, setButtonConfirm] = useState(null);
  const [confirmUnsubscribeButton, setConfirmUnsubscribeButton] =
    useState(false);
  const userData = useSelector((state) => state.userData.userData);
  const [ifUpdated, setIfUpdated] = useState(false);
  const [customValue, setCustomValue] = useState("");
  const [ifCustomConfirm, setIfCustomConfirm] = useState(false);

  const customPlan = {
    price: customValue,
    priceId: "priceId_for_custom",
  };

  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      setLocalUserData(JSON.parse(userData));
      if (userData.petstar_sub === "0.00") {
        setIfUnsubscribe(true);
      }
      setMonthlyAmount(JSON.parse(userData).commission_rate);
    }
  }, [userData]);

  useEffect(() => {
    if (ifUnsubscribe) {
      const timer = setTimeout(() => {
        setIfUnsubscribe(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [ifUnsubscribe]);

  useEffect(() => {
    if (ifUpdated) {
      const timer = setTimeout(() => {
        setIfUpdated(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [ifUpdated]);

  const handleSubscriptionAction = async (status) => {
    if (localUserData && localUserData.stripe_customer_id) {
      try {
        const response = await customerPortal(
          localUserData.stripe_customer_id,
          status
        );
        window.location.href = response.portal_url;
      } catch (error) {
        console.error("Failed to manage subscription:", error);
      }
    }
  };

  const submitUpdate = async (priceId, price) => {
    const updateData = {
      new_price_id: priceId,
      price: price,
    };

    try {
      const data = await petstarSubscriptionUpdate(
        localUserData.id,
        updateData
      );
      setIfUpdated(true);
      window.scrollTo(0, 0);
      return data.response;
    } catch (error) {
      console.error("Error during update:", error);
      setGeneralError(errorHandler(error));
    }
  };

  const handlePlanClick = async (index) => {
    const selectedPlan = plans[index];
    setMonthlyAmount(selectedPlan.price);
    setIfUnsubscribe(false);
    setIfConfirm(false);

    await submitUpdate(selectedPlan.priceId, selectedPlan.price);
  };

  const handleCustomClick = async () => {
    setMonthlyAmount(customPlan.price);
    setIfUnsubscribe(false);
    setIfConfirm(false);

    await submitUpdate(customPlan.priceId, customPlan.price);
  };

  const deleteSubscription = async () => {
    setGeneralError("");

    try {
      const deleteData = {
        new_price_id: "unsubscribe",
      };

      const data = await petstarSubscriptionUpdate(
        localUserData.id,
        deleteData
      );
      setIfUnsubscribe(true);
      setConfirmUnsubscribeButton(false);
      window.scrollTo(0, 0);
      return data.response;
    } catch (error) {
      const errorMessage = errorHandler(error);
      setGeneralError(errorMessage);
    }
  };

  const handleUnsubscribe = () => {
    deleteSubscription();
  };

  const handleCustomInput = (e) => {
    const value = e.target.value;

    if (/^\d*$/.test(value)) {
      setCustomValue(value);
    }
  };

  return (
    <div className={`pt-12 ml-14`}>
      <div>
        <h1 className="text-orange">Current Plan</h1>
        <p className="font-bold">Donation: ${monthlyAmount}/month</p>
        {ifUnsubscribe && (
          <p className="text-success">You are now unsubscribed from PetStar</p>
        )}
        {ifUpdated && (
          <p className="text-success">
            Your subscription has been updated successfully
          </p>
        )}
      </div>
      <div>
        <h1 className="text-orange">Other Plans</h1>
        <p>
          Changing your current plan will automatically modify your monthly
          payment
        </p>
        <div className="flex my-5">
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "1rem",
              overflowX: "auto",
              margin: "0 auto",
            }}
          >
            {plans.map((plan, index) => (
              <Card
                key={index}
                sx={{
                  border: "1px solid #eee",
                  borderRadius: "4px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  maxWidth: "200px",
                  minHeight: "200px",
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <Typography variant="h5" component="h2">
                    ${plan.price}
                  </Typography>
                  <Typography variant="caption" style={{ fontSize: "0.85rem" }}>
                    {plan.description}
                  </Typography>
                  {ifConfirm && buttonConfirm === index ? (
                    <button
                      className="flex justify-center w-20 p-2 text-base text-white bg-blue border-none rounded-[4px] cursor-pointer mx-auto mb-2"
                      onClick={() => handlePlanClick(index)}
                    >
                      Confirm?
                    </button>
                  ) : (
                    <button
                      className="flex justify-center w-20 p-2 text-base text-white bg-blue border-none rounded-[4px] cursor-pointer mx-auto mb-2"
                      onClick={() => {
                        setIfConfirm(true);
                        setButtonConfirm(index);
                      }}
                    >
                      Select
                    </button>
                  )}
                </CardContent>
              </Card>
            ))}
            {/* <Card
            sx={{
              border: "1px solid #eee",
              borderRadius: "4px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              maxWidth: "200px",
              minHeight: "200px",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Typography variant="h5" component="h2">
                Custom
              </Typography>
              <TextField
                variant="outlined"
                value={customValue}
                onChange={handleCustomInput}
                label="..."
                size="small"
                style={{ maxWidth: "100px", minHeight: "10px" }}
              />
              {ifCustomConfirm ? (
                <button
                  className={classes.card_btn}
                  onClick={() => handleCustomClick()}
                >
                  Confirm?
                </button>
              ) : (
                <button
                  className={classes.card_btn}
                  onClick={() => {
                    setIfCustomConfirm(true);
                  }}
                  disabled={customValue === ""}
                >
                  Select
                </button>
              )}
            </CardContent>
          </Card> */}
          </Box>
        </div>

        <div>
          {confirmUnsubscribeButton ? (
            <button
              className="flex justify-center w-40 p-2 text-base text-white bg-blue border-none rounded-[4px] cursor-pointer mx-auto mb-2"
              onClick={() => handleUnsubscribe()}
            >
              Confirm?
            </button>
          ) : (
            <button
              className="flex justify-center w-40 p-2 text-base text-white bg-blue border-none rounded-[4px] cursor-pointer mx-auto mb-2"
              onClick={() => setConfirmUnsubscribeButton(true)}
            >
              Unsubscribe
            </button>
          )}
          <div className="flex flex-col items-center justify-center w-full mt-4">
            {generalError && (
              <Alert severity="warning">
                {typeof generalError === "string"
                  ? generalError
                  : "An unexpected error occurred"}
              </Alert>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Subscription;