import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import classes from "./CreateBlog.module.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Editor from "../../Dashboard/CampaignModal/Editor/Editor";
import GeneralButton from "../../shared/Buttons/GeneralButton";
import Quill from "quill";
import { createBlog } from "../../../api/CreateBlog";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import { lineBreaks } from "../../shared/Utils/LineBreaks";
import { getNonprofits } from "../../../api/Nonprofits";
import PreviewBlog from "./PreviewBlog";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "../../shared/LoadingSpinner/Spinner";
import populateFormData from "../../shared/Utils/populateFormData";
import handleEditorChange from "../../shared/Utils/handleEditorChange";
import updateSeshStorage from "../../shared/Utils/updateSeshStorage";
import { MAXFILESIZE } from "../../../constants";

const Delta = Quill.import("delta");

const CreateBlog = ({ onClose }) => {
  const [blogData, setBlogData] = useState({
    title: sessionStorage.getItem("title") || "",
    category: sessionStorage.getItem("category") || "blogs",
    image_1: "",
    paragraph_1: sessionStorage.getItem("paragraph_1") || "",
    image_2: "",
    paragraph_2: sessionStorage.getItem("paragraph_2") || "",
    image_3: "",
    paragraph_3: sessionStorage.getItem("paragraph_3") || "",
    image_4: "",
    paragraph_4: sessionStorage.getItem("paragraph_4") || "",
    image_5: "",
    paragraph_5: sessionStorage.getItem("paragraph_5") || "",
    image_6: "",
    paragraph_6: sessionStorage.getItem("paragraph_6") || "",
    image_7: "",
    paragraph_7: sessionStorage.getItem("paragraph_7") || "",
    image_8: "",
    paragraph_8: sessionStorage.getItem("paragraph_8") || "",
    image_9: "",
    paragraph_9: sessionStorage.getItem("paragraph_9") || "",
    image_10: "",
    paragraph_10: sessionStorage.getItem("paragraph_10") || "",
    video_urls: sessionStorage.getItem("video_urls") || "",
    nonprofit: sessionStorage.getItem("nonprofit") || "",
    success: "",
    error: "",
  });
  const [category, setCategory] = useState(sessionStorage.getItem("category"));
  const [imagePreviews, setImagePreviews] = useState([]);
  const [createError, setCreateError] = useState("");
  const [npDropdown, setNpDropdown] = useState(true);
  const [npInfo, setNpInfo] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let images = [];

  // get NP data and render it using map into the dropdown
  useEffect(() => {
    const fetchNP = async () => {
      try {
        const response = await getNonprofits();
        setNpInfo(response);
        return response;
      } catch (e) {
        throw e;
      }
    };

    fetchNP();
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("category") === "success") {
      setNpDropdown(false);
    } else {
      sessionStorage.setItem("nonprofit", "");
      setNpDropdown(true);
    }
  }, [category]);

  const handleChange = (e) => {
    const { name, value } = updateSeshStorage(e, setBlogData);

    if (name === "category" && value === "success") {
      setNpDropdown(false);
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const { name } = e.target;

    let error = "";
    let isTooLarge = false;

    if (files.length > 0) {
      // error handling if removed file
      if (files[0]["size"] > MAXFILESIZE) {
        error = "File size is too large (Must be under 3 MB).";
        isTooLarge = true;
      } else {
        images.push(files[0]);
        setCreateError("");
      }
    }

    if (isTooLarge) {
      // error handling if file is too large
      setBlogData((prevState) => ({ ...prevState, error: error }));
      setCreateError(error);
    } else {
      const imagePreview = images.map((img) => {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        return new Promise((resolve) => {
          reader.onloadend = () => {
            resolve(reader.result);
          };
        });
      });

      Promise.all(imagePreview).then((previews) => {
        setImagePreviews(previews);
      });

      setBlogData((prevState) => ({
        ...prevState,
        [name]: files[0],
        error: "", // Clear any previous error
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      let formData = populateFormData(blogData);
      const response = await createBlog(formData);
      setCreateError("");

      setBlogData((prevState) => ({
        ...prevState,
        success: "Blog created successfully!",
        error: "",
      }));

      sessionStorage.clear(); // Clears session storage and inputs
    } catch (e) {
      const errorMessage = errorHandler(e);
      const errorMessageWithBreaks = lineBreaks(errorMessage);
      setCreateError(errorMessageWithBreaks);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePreviewClick = (event) => {
    event.stopPropagation();
    setPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setPreviewOpen(false);
  };

  if (previewOpen) {
    return <PreviewBlog blog={blogData} onClose={() => handleClosePreview()} />;
  }

  return (
    <>
      <div className="flex flex-row mb-4">
        <h1 className={`${classes.back_button} w-1/5`} onClick={onClose}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </h1>
        <h1 className="text-orange">Create a Blog</h1>
      </div>
      <div className={`${classes.blog_form} border p-12 m-2.5`}>
        <form className={`${classes.post_form} w-full`} onSubmit={handleSubmit}>
          <section className={`${classes.top_section} px-5`}>
            <div className={`${classes.cover_image} my-2.5 px-2`}>
              <InputLabel
                className={`${classes.subtitles}`}
                id="demo-simple-select-label"
              >
                Cover Image
              </InputLabel>
              <input
                className={`border p-2 mb-3`}
                type="file"
                name="image_1"
                onChange={handleImageChange}
              />
            </div>
            <TextField
              className="px-2"
              id="title"
              label="Title"
              name="title"
              onChange={handleChange}
              value={blogData.title}
            />
            <TextField
              style={{ paddingLeft: "10px", paddingRight: "5px" }}
              id="video_urls"
              label="Video URLs (comma separated)"
              name="video_urls"
              defaultValue=""
              onChange={handleChange}
              value={blogData.video_urls}
            />
            <FormControl
              sx={{ m: 1, width: 222.97 }}
              size="normal"
              className="px-0"
            >
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                required
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={blogData.category}
                label="category"
                onChange={handleChange}
                name="category"
              >
                <MenuItem value="blogs">Blog</MenuItem>
                <MenuItem value="success">Success Story</MenuItem>
                <MenuItem value="corporate">Corporate Partnership</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              sx={{ m: 1, width: 222.97 }}
              size="normal"
              className="px-0"
            >
              <InputLabel id="demo-simple-select-label">Nonprofit</InputLabel>
              <Select
                disabled={npDropdown}
                required
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={blogData.nonprofit}
                label="nonprofit"
                onChange={handleChange}
                name="nonprofit"
              >
                {npInfo.length > 0 &&
                  npInfo.map((np) => (
                    <MenuItem key={np.id} value={np.id}>
                      {np.org_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </section>
          <section className={`${classes.middle_section} w-full px-5`}>
            <div className={`${classes.img_p_section} border p-2 my-2.5`}>
              <InputLabel
                className={`${classes.subtitles}`}
                id="demo-simple-select-label"
              >
                Paragraph 1
              </InputLabel>
              <div className={`${classes.custom_quill_wrapper}`}>
                <Editor
                  name={"paragraph_1"}
                  onTextChange={(content, delta, source, quill) =>
                    handleEditorChange(
                      content,
                      delta,
                      source,
                      quill,
                      "paragraph_1",
                      setBlogData
                    )
                  }
                  defaultValue={blogData.paragraph_1}
                />
              </div>
            </div>
            <div className={`${classes.img_p_section} border p-2 my-2.5`}>
              <InputLabel
                className={`${classes.subtitles}`}
                id="demo-simple-select-label"
              >
                Paragraph 2
              </InputLabel>
              <input
                className={`border p-2 rounded mb-2`}
                type="file"
                name="image_2"
                onChange={handleImageChange}
              />
              <div className={`${classes.custom_quill_wrapper}`}>
                <Editor
                  name={"paragraph_2"}
                  onTextChange={(content, delta, source, quill) =>
                    handleEditorChange(
                      content,
                      delta,
                      source,
                      quill,
                      "paragraph_2",
                      setBlogData
                    )
                  }
                  defaultValue={blogData.paragraph_2}
                />
              </div>
            </div>
            <div className={`${classes.img_p_section} border p-2 my-2.5`}>
              <InputLabel
                className={`${classes.subtitles}`}
                id="demo-simple-select-label"
              >
                Paragraph 3
              </InputLabel>
              <input
                className={`border p-2 rounded mb-2`}
                type="file"
                name="image_3"
                onChange={handleImageChange}
              />
              <div className={`${classes.custom_quill_wrapper}`}>
                <Editor
                  name={"paragraph_3"}
                  onTextChange={(content, delta, source, quill) =>
                    handleEditorChange(
                      content,
                      delta,
                      source,
                      quill,
                      "paragraph_3",
                      setBlogData
                    )
                  }
                  defaultValue={blogData.paragraph_3}
                />
              </div>
            </div>
            <div className={`${classes.img_p_section} border p-2 my-2.5`}>
              <InputLabel
                className={`${classes.subtitles}`}
                id="demo-simple-select-label"
              >
                Paragraph 4
              </InputLabel>
              <input
                className={`border p-2 rounded mb-2`}
                type="file"
                name="image_4"
                onChange={handleImageChange}
              />
              <div className={`${classes.custom_quill_wrapper}`}>
                <Editor
                  name={"paragraph_4"}
                  onTextChange={(content, delta, source, quill) =>
                    handleEditorChange(
                      content,
                      delta,
                      source,
                      quill,
                      "paragraph_4",
                      setBlogData
                    )
                  }
                  defaultValue={blogData.paragraph_4}
                />
              </div>
            </div>
            <div className={`${classes.img_p_section} border p-2 my-2.5`}>
              <InputLabel
                className={`${classes.subtitles}`}
                id="demo-simple-select-label"
              >
                Paragraph 5
              </InputLabel>
              <input
                className={`border p-2 rounded mb-2`}
                type="file"
                name="image_5"
                onChange={handleImageChange}
              />
              <div className={`${classes.custom_quill_wrapper}`}>
                <Editor
                  name={"paragraph_5"}
                  onTextChange={(content, delta, source, quill) =>
                    handleEditorChange(
                      content,
                      delta,
                      source,
                      quill,
                      "paragraph_5",
                      setBlogData
                    )
                  }
                  defaultValue={blogData.paragraph_5}
                />
              </div>
            </div>
            <div className={`${classes.img_p_section} border p-2 my-2.5`}>
              <InputLabel
                className={`${classes.subtitles}`}
                id="demo-simple-select-label"
              >
                Paragraph 6
              </InputLabel>
              <input
                className={`border p-2 rounded mb-2`}
                type="file"
                name="image_6"
                onChange={handleImageChange}
              />
              <div className={`${classes.custom_quill_wrapper}`}>
                <Editor
                  name={"paragraph_6"}
                  onTextChange={(content, delta, source, quill) =>
                    handleEditorChange(
                      content,
                      delta,
                      source,
                      quill,
                      "paragraph_6",
                      setBlogData
                    )
                  }
                  defaultValue={blogData.paragraph_6}
                />
              </div>
            </div>
            <div className={`${classes.img_p_section} border p-2 my-2.5`}>
              <InputLabel
                className={`${classes.subtitles}`}
                id="demo-simple-select-label"
              >
                Paragraph 7
              </InputLabel>
              <input
                className={`border p-2 rounded mb-2`}
                type="file"
                name="image_7"
                onChange={handleImageChange}
              />
              <div className={`${classes.custom_quill_wrapper}`}>
                <Editor
                  name={"paragraph_7"}
                  onTextChange={(content, delta, source, quill) =>
                    handleEditorChange(
                      content,
                      delta,
                      source,
                      quill,
                      "paragraph_7",
                      setBlogData
                    )
                  }
                  defaultValue={blogData.paragraph_7}
                />
              </div>
            </div>
            <div className={`${classes.img_p_section} border p-2 my-2.5`}>
              <InputLabel
                className={`${classes.subtitles}`}
                id="demo-simple-select-label"
              >
                Paragraph 8
              </InputLabel>
              <input
                className={`border p-2 rounded mb-2`}
                type="file"
                name="image_8"
                onChange={handleImageChange}
              />
              <div className={`${classes.custom_quill_wrapper}`}>
                <Editor
                  name={"paragraph_8"}
                  onTextChange={(content, delta, source, quill) =>
                    handleEditorChange(
                      content,
                      delta,
                      source,
                      quill,
                      "paragraph_8",
                      setBlogData
                    )
                  }
                  defaultValue={blogData.paragraph_8}
                />
              </div>
            </div>
            <div className={`${classes.img_p_section} border p-2 my-2.5`}>
              <InputLabel
                className={`${classes.subtitles}`}
                id="demo-simple-select-label"
              >
                Paragraph 9
              </InputLabel>
              <input
                className={`border p-2 rounded mb-2`}
                type="file"
                name="image_9"
                onChange={handleImageChange}
              />
              <div className={`${classes.custom_quill_wrapper}`}>
                <Editor
                  name={"paragraph_9"}
                  onTextChange={(content, delta, source, quill) =>
                    handleEditorChange(
                      content,
                      delta,
                      source,
                      quill,
                      "paragraph_9",
                      setBlogData
                    )
                  }
                  defaultValue={blogData.paragraph_9}
                />
              </div>
            </div>
            <div className={`${classes.img_p_section} border p-2 my-2.5`}>
              <InputLabel
                className={`${classes.subtitles}`}
                id="demo-simple-select-label"
              >
                Paragraph 10
              </InputLabel>
              <input
                className={`border p-2 rounded mb-2`}
                type="file"
                name="image_10"
                onChange={handleImageChange}
              />
              <div className={`${classes.custom_quill_wrapper}`}>
                <Editor
                  name={"paragraph_10"}
                  onTextChange={(content, delta, source, quill) =>
                    handleEditorChange(
                      content,
                      delta,
                      source,
                      quill,
                      "paragraph_10",
                      setBlogData
                    )
                  }
                  defaultValue={blogData.paragraph_10}
                />
              </div>
            </div>
          </section>
          <div className={classes.button_container}>
            <GeneralButton
              className="w-40"
              colour={"blue"}
              onSubmit={handleSubmit}
              disabled={isLoading}
            >
              Submit
            </GeneralButton>
            <GeneralButton
              className="w-40"
              colour={"blue"}
              onClick={handlePreviewClick}
            >
              Preview
            </GeneralButton>
          </div>
          {isLoading && (
            <div className="flex justify-center items-center">
              <Spinner size={30} />
            </div>
          )}
          {!createError && <p style={{ color: "green" }}>{blogData.success}</p>}
          {createError ? <p style={{ color: "red" }}>{createError}</p> : ""}
        </form>
      </div>
    </>
  );
};

export default CreateBlog;
