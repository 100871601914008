import { useEffect } from "react";
import { parseDescription } from "../../shared/Utils/ParseDescription";

function ArticlePage({article, onClose}) {
    const {title, image, content, created_at} = article || {};

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const calculateReadingTime = (text) => {
        const wordsPerMinute = 225;
        const words = text.replace(/<[^>]+>/g, "").split(/\s+/).length;
        const minutes = words / wordsPerMinute;
        return Math.ceil(minutes);
      };
    
    return (
        <div className="flex flex-col">
            <button 
                className="bg-orange w-48 flex justify-center items-center rounded-lg text-white py-2"
                onClick={() => onClose()}>Back</button>
            <section className="flex flex-col justify-center items-center">
                <h1 className="text-2xl text-black mt-8 mb-8">{title}</h1>
                <img src={image} className="w-4/5 h-96 object-fit rounded-3xl"/>
                <div className="w-full mb-8">
                    <div className="flex justify-start gap-8 mt-4 ml-32">
                        <p className="flex gap-2 justify-center items-center text-sm">
                            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_3077_15114)">
                            <path d="M6.42857 1.25V2.5H11.5714V1.25C11.5714 0.559766 12.146 0 12.8571 0C13.5683 0 14.1429 0.559766 14.1429 1.25V2.5H16.0714C17.1362 2.5 18 3.33945 18 4.375V6.25H0V4.375C0 3.33945 0.863437 2.5 1.92857 2.5H3.85714V1.25C3.85714 0.559766 4.4317 0 5.14286 0C5.85402 0 6.42857 0.559766 6.42857 1.25ZM0 7.5H18V18.125C18 19.1602 17.1362 20 16.0714 20H1.92857C0.863437 20 0 19.1602 0 18.125V7.5ZM2.57143 11.875C2.57143 12.2188 2.85911 12.5 3.21429 12.5H4.5C4.85357 12.5 5.14286 12.2188 5.14286 11.875V10.625C5.14286 10.2812 4.85357 10 4.5 10H3.21429C2.85911 10 2.57143 10.2812 2.57143 10.625V11.875ZM7.71429 11.875C7.71429 12.2188 8.00357 12.5 8.35714 12.5H9.64286C9.99643 12.5 10.2857 12.2188 10.2857 11.875V10.625C10.2857 10.2812 9.99643 10 9.64286 10H8.35714C8.00357 10 7.71429 10.2812 7.71429 10.625V11.875ZM13.5 10C13.1464 10 12.8571 10.2812 12.8571 10.625V11.875C12.8571 12.2188 13.1464 12.5 13.5 12.5H14.7857C15.1393 12.5 15.4286 12.2188 15.4286 11.875V10.625C15.4286 10.2812 15.1393 10 14.7857 10H13.5ZM2.57143 16.875C2.57143 17.2188 2.85911 17.5 3.21429 17.5H4.5C4.85357 17.5 5.14286 17.2188 5.14286 16.875V15.625C5.14286 15.2812 4.85357 15 4.5 15H3.21429C2.85911 15 2.57143 15.2812 2.57143 15.625V16.875ZM8.35714 15C8.00357 15 7.71429 15.2812 7.71429 15.625V16.875C7.71429 17.2188 8.00357 17.5 8.35714 17.5H9.64286C9.99643 17.5 10.2857 17.2188 10.2857 16.875V15.625C10.2857 15.2812 9.99643 15 9.64286 15H8.35714ZM12.8571 16.875C12.8571 17.2188 13.1464 17.5 13.5 17.5H14.7857C15.1393 17.5 15.4286 17.2188 15.4286 16.875V15.625C15.4286 15.2812 15.1393 15 14.7857 15H13.5C13.1464 15 12.8571 15.2812 12.8571 15.625V16.875Z" fill="#1C1C1C"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_3077_15114">
                            <rect width="18" height="20" fill="white"/>
                            </clipPath>
                            </defs>
                            </svg>
                            {new Date(article.created_at).toLocaleDateString("en-US", 
                            {month: "short", day: "numeric", year: "numeric"})} 
                        </p>
                        <p className="flex gap-2 justify-center items-center text-sm">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.5631 11.7659L10.7746 9.67449V5.41426C10.7746 4.9859 10.4283 4.63965 9.99995 4.63965C9.57159 4.63965 9.22534 4.9859 9.22534 5.41426V10.0618C9.22534 10.3058 9.33999 10.5359 9.53519 10.6815L12.6335 13.0053C12.773 13.1099 12.9357 13.1602 13.0975 13.1602C13.3338 13.1602 13.5662 13.0541 13.718 12.8496C13.9752 12.508 13.9055 12.0223 13.5631 11.7659Z" fill="#1C1C1C"/>
                            <path d="M10 0C4.48566 0 0 4.48566 0 10C0 15.5143 4.48566 20 10 20C15.5143 20 20 15.5143 20 10C20 4.48566 15.5143 0 10 0ZM10 18.4508C5.34082 18.4508 1.54918 14.6592 1.54918 10C1.54918 5.34082 5.34082 1.54918 10 1.54918C14.66 1.54918 18.4508 5.34082 18.4508 10C18.4508 14.6592 14.6592 18.4508 10 18.4508Z" fill="#1C1C1C"/>
                            </svg>
                            {calculateReadingTime(article.content) + '+ minute read'}</p>
                    </div>
                </div>
                <div
                    className="text-left"
                    dangerouslySetInnerHTML={{
                    __html: parseDescription(content),
                    }}
                />
            </section>
        </div>
    )
}

export default ArticlePage;