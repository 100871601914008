import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import SidebarAdmin from "./SidebarAdmin/SidebarAdmin";
import classes from "./AdminDashboard.module.css";
import { dashboard } from "../../api/Dashboard";
import { useNavigate } from "react-router-dom";
import DisplayBlogs from "./Blogs/DisplayBlogs";
import Approvals from "./Approvals/Approvals";
import EventsMainPage from "./Events/EventsMainPage";
import Resources from "./Resources/Resources";
import Updates from "./Updates/Updates";

const AdminDashboard = () => {
  const [tab, setTab] = useState("Blogs");
  const [localUserData, setLocalUserData] = useState(null);
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDataString = localStorage.getItem("userData");
        if (userDataString) {
          const userData = JSON.parse(userDataString);
          const response = await dashboard(userData.id);
          setLocalUserData(response);
          localStorage.setItem("userData", JSON.stringify(response));
        }
      } catch (error) {
        console.error(
          "Failed to parse user data or fetch user profile: ",
          error
        );
        localStorage.removeItem("userData");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessToken");
        navigate("/");
      }
    };

    fetchUserData();
  }, [tab]);

  if (!localUserData) {
    return (
      <div className="container">
        <p>Sign Up or Login to view your Profile</p>
      </div>
    );
  }

  const renderTabContent = () => {
    switch (tab) {
      case "Blogs":
        return <DisplayBlogs />;
      case "Nonprofits":
        return <Approvals />;
      case "Metrics":
        return <p>Metrics</p>;
      case "Events":
        return <EventsMainPage />;
      case "Resources":
        return <Resources />;
      case "Updates":
        return <Updates />;
      default:
        return <DisplayBlogs />;
    }
  };

  return (
    <div>
      <Box
        sx={{ display: "flex", flexGrow: 1 }}
        className={classes.admin_dashboard}
      >
        <SidebarAdmin
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          setTab={setTab}
        />
        <Box
          component="main"
          flexGrow={1}
          p={3}
          sx={{
            transition: "margin 0.3s ease",
            marginLeft: collapsed ? "64px" : "60px",
            flex: 1,
          }}
        >
          {renderTabContent()}
        </Box>
      </Box>
    </div>
  );
};

export default AdminDashboard;
