// Checking if paragraph is ""
// quill translates "" to '\"\"'
const handleEditorChange = (content, delta, source, quill, name, setData) => {
  const sanitizedContent =
    content === "<p><br></p>" || content === '<p>""</p>' || content === ""
      ? ""
      : content;

  if (sanitizedContent !== "") {
    setData((prevState) => ({
      ...prevState,
      [name]: sanitizedContent,
    }));
    sessionStorage.setItem(name, sanitizedContent);
  } else {
    sessionStorage.removeItem(name);
  }
};

export default handleEditorChange;
