function Videos() {
    return (
        <div className="m-0 p-4 w-full xl:w-4xl h-full rounded-xl bg-blue px-8">
          <div className={`flex flex-col mb-4`}>
            <h1 className="text-white !text-3xl mb-4">Instructional Videos</h1>
            <p className="text-xl text-white font-semibold">Guides on how to use Sponsor A Pet's platform</p>
          </div>
          <div className={`flex flex-col bg-white border border-[var(--light-grey)] rounded-2xl p-7 mb-7`}>
            <p className="font-semibold text-2xl text-blue mb-6">Navigating the Nonprofit Dashboard</p>
            <div className="flex justify-center">
              <iframe
                width="720"
                height="400"
                src="https://www.youtube.com/embed/fb7luHl9GO8?list=PLELFenzodbTGgZvPRlONBqvmTKLmpNaHY"
                title="Navigating the Nonprofit Dashboard"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className={`flex flex-col bg-white border border-[var(--light-grey] rounded-2xl p-7 mb-4`}>
            <p className="font-semibold text-2xl text-blue mb-6">Creating Animal Campaigns</p>
            <div className="flex justify-center">
              <iframe
                width="720"
                height="400"
                src="https://www.youtube.com/embed/S1yLV5g3KXA?list=PLELFenzodbTGgZvPRlONBqvmTKLmpNaHY"
                title="Creating Animal Campaigns"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className={`flex flex-col bg-white border border-[var(--light-grey)] rounded-2xl p-7 mb-4`}>
            <p className="font-semibold text-2xl text-blue mb-6">Campaign Modifications & Updates</p>
            <div className="flex justify-center">
              <iframe
                width="720"
                height="400"
                src="https://www.youtube.com/embed/rnZonX28mHs?list=PLELFenzodbTGgZvPRlONBqvmTKLmpNaHY"
                title="Campaign Modifications &amp; Updates"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          </div>
      </div>
      )
}

export default Videos;