export const isWithinLastWeek = (date) => {
  let currentDate = new Date();
  let differenceMilliseconds = currentDate - date;
  let millisecondsInDay = 1000 * 60 * 60 * 24;
  let daysDifference = Math.floor(differenceMilliseconds / millisecondsInDay);
  return daysDifference <= 7;
};

export const IsWithinLastMonth = (date) => {
  let currentDate = new Date();
  return (
    date.getFullYear() === currentDate.getFullYear() &&
    date.getMonth() === currentDate.getMonth()
  );
};
