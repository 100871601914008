import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faMagnifyingGlass,
  faCartShopping,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { getShopItems } from "../../../api/ShopItems";
import { subscribeMarketingNewsletter } from "../../../api/SubscribeNewsletter";
import GeneralButton from "../../shared/Buttons/GeneralButton";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import { lineBreaks } from "../../shared/Utils/LineBreaks";
import { useSelector } from "react-redux";
import Spinner from "../../shared/LoadingSpinner/Spinner"

export default function ShopSearch() {
  const [shopItems, setShopItems] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const [localUserData, setLocalUserData] = useState(null);
  const [email, setEmail] = useState("");
  const [errorState, setErrorState] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userData.userData);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // getting list of partners from api
  useEffect(() => {
    try {
      getShopItems().then((response) => {
        setShopItems(response);
      }).finally(() => {
        setIsLoading(false);
      })
    } catch (error) {
      console.error(`Could not retrieve list of shop items`);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      setLocalUserData(JSON.parse(userData));
    }
  }, [userData]);

  // Newsletter Submit functions
  const handleSubmit = (event) => {
    event.preventDefault();
    subscribeToNewsletter(email);
  };

  const subscribeToNewsletter = async (email) => {
    try {
      const payload = {
        email: email,
        active: false,
      };
      await subscribeMarketingNewsletter(payload);
      setErrorState("Successfully Sent Email");

      setTimeout(() => {
        setErrorState("");
      }, 2000);
    } catch (error) {
      let errorStr = errorHandler(error);
      errorStr = errorStr.substring(7, errorStr.length - 1);
      const errorMessageWithBreaks = lineBreaks(errorStr);
      setErrorState(errorMessageWithBreaks);
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  // creating input callback for SEARCH bar
  const handleSearch = (e) => {
    setSearchParam(e.target.value);
  };

  return (
    <div
      className="sm:grow flex flex-col justify-center items-center w-full !my-0 !py-32"
    >
      <h1 className="text-orange mb-4">Shop Sponsor a Pet</h1>
      <header className="flex flex-col items-center justify-center w-3/5 mb-7">
        <h5 className="my-4 font-bold">
          Subscribe to find out first about our new items:
        </h5>
        <form
          className="w-full md:w-1/5 flex justify-center items-center gap-6"
          onSubmit={handleSubmit}
        >
          <input
            className="font-base bg-grey-light p-2 flex justify-center items-center border-none outline-none rounded w-60" 
            type="email"
            id="newsletter-email"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
          />
          <GeneralButton colour="blue" type="submit" className="w-32">
            Subscribe
          </GeneralButton>
        </form>
      </header>
      {errorState && (
        <p
          className={`${
            errorState === "Successfully Sent Email"
              ? "text-green-500"
              : "text-red-500"
          } -mt-5 mb-4`}
        >
          {errorState}
        </p>
      )}
      <div className="flex justify-between gap-8 w-4/5 md:w-2/5">
        <div className="w-full flex items-center my-0 mx-auto border border-grey rounded-full overflow-hidden h-9">
          <FontAwesomeIcon icon={faMagnifyingGlass} className="cursor-default text-grey my-0 mx-2" />
          <input
            type="text"
            placeholder="Search Products by Name"
            onInput={handleSearch}
            className="flex-1 border-none outline-none"
          />
        </div>
        <div className="cursor-pointer flex justify-center items-center">
          <Link className="flex visited:text-orange visited:no-underline hover:text-orange active:text-orange focus:orange" to={`/cart`}>
            <FontAwesomeIcon
              icon={faCartShopping}
              className="text-2xl my-0 mx-2 text-orange"
            />
          </Link>
        </div>
      </div>
      <div className="max-w-7xl w-full flex flex-wrap justify-center gap-12 my-0 mt-8 mx-auto py-0 px-4 lg:px-20">
        {!isLoading ? (shopItems.map((item, index) => {
          // make a check to see if we want to render this item or not
          if (
            !item.title.toLowerCase().includes(searchParam.toLowerCase()) ||
            !item.visible
          )
            return "";

          // we have determined this card matches the search param. render it
          return (
            <Link to={`/shop/${item.product_id}`} key={index}>
              <div 
                className="!w-72 !h-96 flex flex-col rounded-lg shadow-cardShadow overflow-hidden bg-white text-black p-4 md:p-5 gap-3 text-left select-none hover:shadow-cardHoverShadow"
                key={item.product_id}>
                {item.first_image_url ? (
                  <img src={item.first_image_url} alt={item.title} className="w-full h-48 my-0 mx-auto object-contain overflow-hidden"/>
                ) : (
                  <img src="" alt={item.title} className="w-full h-48 my-0 mx-auto object-contain overflow-hidden"/>
                )}
                <div className="w-full flex flex-col justify-between flex-1 text-left">
                  <h3 className="text-xl font-bold w-full m-0 text-blue font-fingerpaint">{item.title}</h3>
                  <p className="m-0 mt-2 mb-3 flex-1">From ${(item.first_price / 100).toFixed(2)}</p>
                  <div className="inline-flex justify-center items-center no-underline cursor-pointer text-black text-3xl">
                    <FontAwesomeIcon icon={faArrowRight} className="ml-auto w-6"/>
                  </div>
                </div>
              </div>
            </Link>
          );
        })) : (
          <div className="flex justify-center items-center mx-auto my-16">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
}
