const populateFormData = (data) => {
  let formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (
      key !== "" &&
      key !== "success" &&
      key !== "error" &&
      key.includes("paragraph || content") === false
    ) {
      formData.append(key, data[key]);
    }

    if (key.includes("paragraph || content")) {
      if (
        data[key] &&
        data[key] !== "" &&
        data[key] !== "<p><br></p>" &&
        data[key] !== '<p>""</p>'
      ) {
        formData.append(key, data[key]);
      }
    }
  });

  return formData;
};

export default populateFormData;
